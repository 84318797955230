import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import ActivityList from './ActivityList/ActivityList';
import LessonReview from '../LessonReview/LessonReview';

import LessonCompletedIcon from '../../../../assets/images/svg/lesson-completed-icon.svg';
import LessonInProgressIcon from '../../../../assets/images/svg/lesson-in-progress-icon.svg';
import LessonNotProgressIcon from '../../../../assets/images/svg/lesson-not-progress-icon.svg';
import './LessonList.css';

/**
 * TOC LessonList component
 * @param {object} unitData
 * @param {number} levelPercentage
 * @param {number} reviewMode
 * @returns {Component}
 */
const LessonList = ({ unitData, levelPercentage, reviewMode }) => {
  const { studentid, levelid } = useParams();
  const history = useHistory();
  const userState = JSON.parse(localStorage.getItem('UserState'));
  const lessonData = unitData.lessons;
  const enableReview = (reviewMode === 1 || unitData.completionPercentage === 100);

  /**
   * Function to redirect to player route with the last activity in lesson
   * @param {object} lesson
   * @param {boolean} reviewEnabled
   */
  const launchPlayer = (lesson, reviewEnabled) => {
    history.push({
      pathname: `/${userState.isStaffView ? 'staff' : 'student'}/workbooksplayer/${lesson.lessonId}`,
      state: {
        lesson,
        studentId: studentid,
        levelid,
        reviewEnabled
      }
    });
  };

  /**
   * To change lesson icon
   * @param {number} status
   */
  const LessonIcon = (status) => {
    if (status === 1) {
      return LessonInProgressIcon;
    }
    if (status === 2) {
      return LessonCompletedIcon;
    }
    return LessonNotProgressIcon;
  };

  return (
    <div className="mdc-layout-grid__inner dsw-lesson-container">
      {lessonData
        && lessonData.map((lesson, index) => {
          return (
            <div
              className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
              key={index}
            >
              <div
                className={`mdc-layout-grid__inner dsw-lesson-block ${reviewMode ? '' : 'pointer'}`}
                onClick={() => {
                  return reviewMode ? false : launchPlayer(lesson, false);
                }}
              >
                <div className="mdc-layout-grid__cell--span-1-phone
                  mdc-layout-grid__cell--span-2-tablet
                  mdc-layout-grid__cell--span-2-desktop"
                >
                  <label className="dsw-lesson-label">
                    {`Lesson ${lesson.lessonSequence}`}
                  </label>
                </div>
                <div className="mdc-layout-grid__cell--span-1-phone
                  mdc-layout-grid__cell--span-1-tablet
                  mdc-layout-grid__cell--span-2-desktop"
                >
                  <img
                    className="dsw-lesson-icon"
                    src={LessonIcon(lesson.status)}
                    alt="dsw-lesson-icon"
                  />
                </div>
                <div className="mdc-layout-grid__cell--span-2-phone
                  mdc-layout-grid__cell--span-4-tablet
                  mdc-layout-grid__cell--span-6-desktop
                  padding-top-7"
                >
                  <ActivityList
                    activitiesData={lesson.activities}
                    activityCount={lesson.activityCount}
                    levelPercentage={levelPercentage}
                  />
                </div>
                <div className="mdc-layout-grid__cell--span-1-phone
                  mdc-layout-grid__cell--span-1-tablet
                  mdc-layout-grid__cell--span-2-desktop
                  padding-top-5"
                >
                  {enableReview
                  && (
                  <LessonReview
                    LessonId={lesson.lessonId}
                    launchPlayer={launchPlayer}
                  />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

LessonList.propTypes = {
  unitData: PropTypes.object.isRequired,
  levelPercentage: PropTypes.number.isRequired,
  reviewMode: PropTypes.number.isRequired
};

export default LessonList;
