import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import BookTile from './BookTile/BookTile';
import CarouselCustomButton from '../../CarouselCustomButton/CarouselCustomButton';
import { TILE_VIEW_CAROUCEL_CONFIG } from '../../../constants/CONSTANTS';
import './TileView.css';

/**
 * Mylibrary tileview component
 * @param {Array<object>} levelData
 * @param {string} studentId
 * @param {string} currentLevel
 * @returns {Component}
 */
const TileView = ({ levelData, studentId, currentLevel }) => {
  const carouselRef = useRef();
  const userState = JSON.parse(localStorage.getItem('UserState'));
  const [renderCarousel, setRenderCarousel] = useState('d-none');
  // To calculate current slide based on current level
  const currentSlide = (Math.ceil(currentLevel / 4) - 1) * 4;

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goToSlide(currentSlide, true);
      setRenderCarousel('');
    }
  }, [carouselRef]);

  return (
    <>
      {levelData
        && (
        <Carousel
          containerClass={`dsw-carousel-container ${renderCarousel}`}
          dotListClass="dsw-carousel-dot"
          itemClass="dsw-carousel-item"
          ref={carouselRef}
          responsive={TILE_VIEW_CAROUCEL_CONFIG}
          renderButtonGroupOutside
          centerMode={false}
          autoPlay={false}
          keyBoardControl
          swipeable
          removeArrowOnDeviceType={['tablet', 'mobile']}
          slidesToSlide={4}
          showDots
          ssr={false}
          arrows={false}
          customButtonGroup={<CarouselCustomButton />}
          draggable
        >
          {levelData.map((level, index) => {
            return (
              <BookTile
                levelDetails={level}
                studentId={studentId}
                isCurrentLevel={currentLevel == (index + 1) && !userState.isStaffView}
                key={index}
              />
            );
          })}
        </Carousel>
        )}
    </>
  );
};

TileView.propTypes = {
  levelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentId: PropTypes.string.isRequired,
  currentLevel: PropTypes.string.isRequired
};

export default TileView;
