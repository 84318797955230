import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import MyLibrary from './components/MyLibrary/MyLibrary';
import TableOfContent from './components/TableOfContent/TableOfContent';
import Player from './components/Player/Player';
import { isAuthorized } from './helpers/getAuthorizedState/getAuthorizedState';

import NotificationConfigContext from './context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from './context/CornerLoaderContext/CornerLoaderContext';
import InitialLoadingContext from './context/InitialLoadingContext/InitialLoadingContext';

const AppRoute = () => {
  const notificationContext = useContext(NotificationConfigContext);
  const loaderContext = useContext(CornerLoaderContext);
  const initialLoadingContext = useContext(InitialLoadingContext);
  const isAuth = isAuthorized(initialLoadingContext, notificationContext, loaderContext);
  return (initialLoadingContext.initialLoading && (
    <Switch>
      <AuthorizedRoute
        path="/"
        exact
        component={MyLibrary}
        isAuth={isAuth}
      />
      <AuthorizedRoute
        path="/mylibrary"
        exact
        component={MyLibrary}
        isAuth={isAuth}
      />
      <AuthorizedRoute
        path="/:studentid/toc/:levelid"
        exact
        component={TableOfContent}
        isAuth={isAuth}
      />
      <AuthorizedRoute
        path="/logout"
        exact
        isAuth={false}
      />
      <AuthorizedRoute
        path="/student/workbooksplayer/:id"
        exact
        component={Player}
        isPlayer
        isAuth={isAuth}
      />
      <AuthorizedRoute
        path="/staff/workbooksplayer/:id"
        exact
        component={Player}
        isPlayer
        isAuth={isAuth}
      />
      <AuthorizedRoute
        path="*"
        exact
        component={MyLibrary}
        isAuth={isAuth}
      />
    </Switch>
  ));
};

export default AppRoute;
