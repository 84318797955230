import React from 'react';
import PropTypes from 'prop-types';
import ListProgressTile from '../../ListProgressTile/ListProgressTile';

import './ListView.css';

/**
 * MyLibrary listview component
 * @param {Array<object>} levelData
 * @param {string} studentId
 * @returns {Component}
 */
const ListView = ({ levelData, studentId }) => {
  return (
    <div className="d-flex">
      {levelData
        && (
          <>
            <div className="dsw-list-rectangle margin-right-5">
              <ListProgressTile levelProgressData={levelData.slice(0, 10)} studentId={studentId} />
            </div>
            <div className="dsw-list-rectangle margin-left-5">
              <ListProgressTile levelProgressData={levelData.slice(10, 20)} studentId={studentId} />
            </div>
          </>
        )}
    </div>
  );
};

ListView.propTypes = {
  levelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentId: PropTypes.string.isRequired
};

export default ListView;
