import { NOTIFICATION_TYPE } from '../../constants/CONSTANTS';

/**
 * To get notification icon class
 * @param {string} notificationType
 * @returns {string}
 */
const getNotificationIcon = (notificationType) => {
  switch (notificationType) {
    case NOTIFICATION_TYPE.ATTENTION:
      return 'fa-warning';
    case NOTIFICATION_TYPE.CORRECT:
      return 'fa-check';
    case NOTIFICATION_TYPE.INFORMATION:
      return 'fa-info';
    case NOTIFICATION_TYPE.INCORRECT:
      return 'fa-exclamation-circle';
    default:
      return 'fa-check';
  }
};

export default getNotificationIcon;
