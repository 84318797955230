/**
 * To map Activity List
 * @param {number} activityCount
 * @param {Array<object>} activities
 * @returns {Array<object>}
 */
const mapActivityList = (activityCount, activities) => {
  const mappedActivity = activities;
  for (let activity = 1; activity <= activityCount; activity += 1) {
    const isNotAvailableActivity = !activities.some((eachActivity) => {
      return eachActivity.activitySequence === activity;
    });
    if (isNotAvailableActivity) {
      mappedActivity.push({
        activitySequence: activity,
        isCompleted: false,
        isLastActivityInLesson: false,
        isLastActivityInLevel: false
      });
    }
  }
  return mappedActivity.sort((a, b) => { return a.activitySequence - b.activitySequence; });
};

export default mapActivityList;
