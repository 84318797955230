import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import getNotificationIcon from '../../helpers/getNotificationIcon/getNotificationIcon';
import NotificationConfigContext from '../../context/NotificationConfig/NotificationConfigContext';

import './Notification.css';

/**
 * Notification component
 */
const Notification = () => {
  const [isActive, setisActive] = useState(false);
  const config = useContext(NotificationConfigContext).notificationConfig;

  useEffect(() => {
    if (config) {
      setisActive(true);
      document.body.style.marginTop = '60px';
    }
    setTimeout(() => {
      setisActive(false);
      document.body.style.marginTop = '0px';
    }, 5000);
  }, [config]);

  if (!config) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {isActive && (
        <div className={`dsw-notification-bar ${config.type}`}>
          <div className="container">
            <div className="dsw-notification-left-icon">
              <i className={`fa ${getNotificationIcon(config.type)}`} />
            </div>
            <div className="dsw-notification-center">
              <div className="dsw-notification__center__title">{config.title}</div>
              <div className="dsw-notification__center__description">{config.message}</div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.getElementById('global-notification')
  );
};

export default Notification;
