import { apiRequest } from '../../../helpers/apiRequest/apiRequest';
import { LOGOUT_URL } from '../../../constants/ROUTECONSTANTS';
import { rollbar, logErrorFromPromise } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get Student Level Info
 * @param {string} userId
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @returns
 */
const getStudentLevelInfo = (userId, notificationContext, loaderContext) => {
  loaderContext.enableLoader(true);
  const url = `${process.env.REACT_APP_LS_API_URL}v2/users/${userId}/state?fields=studentLevelInfo`;
  return apiRequest('GET', url).then((response) => {
    loaderContext.enableLoader(false);
    if (response && response.status === 401) {
      localStorage.clear();
      window.location.href = LOGOUT_URL;
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response.json();
  })
    .catch((response) => {
      loaderContext.enableLoader(false);
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      rollbar.error(response);
    });
};

export default getStudentLevelInfo;
