/**
 * To map List Of Levels
 * @param {object} apiListData
 * @returns {Array}
 */
const mapListOfLevels = (apiListData, isStaffView) => {
  const totalLevels = 20;
  const listLevelData = apiListData.levels
    ? apiListData.levels.map((obj) => {
      return {
        id: obj.id,
        progress: obj.reviewMode ? 100 : obj.progress,
        inProgress: true
      };
    })
    : [];

  for (let id = 1; id <= totalLevels; id += 1) {
    const islevelAvailable = listLevelData.some((level) => {
      return level.id === id;
    });
    if (!islevelAvailable) {
      listLevelData.push({
        id,
        progress: 0,
        inProgress: isStaffView || false
      });
    }
  }
  return listLevelData.sort((a, b) => { return a.id - b.id; });
};

export default mapListOfLevels;
