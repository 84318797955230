import { useLocation, useHistory } from 'react-router-dom';
import { LOGOUT_URL } from '../../constants/ROUTECONSTANTS';

import setUserDetails from '../setUserDetails/setUserDetails';

/**
 * To logout and redirect to NSE app
 */
export const appLogout = () => {
  localStorage.clear();
  window.location.href = LOGOUT_URL;
};

/**
 * To set authorization data
 * @param {context} initialLoadingContext
 * @param {context} notificationContext
 * @param {context} loaderContext
 */
const setAuthData = (initialLoadingContext, notificationContext, loaderContext) => {
  const query = new URLSearchParams(useLocation().search);
  if (query.get('Auth') && query.get('UserId')) {
    localStorage.clear();
    localStorage.setItem('Auth', query.get('Auth'));
    localStorage.setItem('InCenter', query.get('InCenter'));
    const userId = query.get('UserId');
    query.delete('Auth');
    query.delete('UserId');
    query.delete('InCenter');
    useHistory().replace({
      search: query.toString()
    });
    setUserDetails(userId, initialLoadingContext, notificationContext, loaderContext);
  } else if (localStorage.getItem('Auth') && localStorage.getItem('UserState')) {
    initialLoadingContext.setInitialLoadingHandler(true);
  } else if (!localStorage.getItem('Auth')) {
    appLogout();
  }
};

/**
 * To get authorize state
 * @param {context} initialLoadingContext
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @returns {boolean}
 */
export const isAuthorized = (initialLoadingContext, notificationContext, loaderContext) => {
  setAuthData(initialLoadingContext, notificationContext, loaderContext);
  return !!localStorage.getItem('Auth');
};
