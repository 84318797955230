import React from 'react';
import PropTypes from 'prop-types';
import FilePdfIcon from '../../../../assets/images/svg/pdf-file-icon.svg';

import './CreditsTable.css';

/**
 * CreditsTable of image credits component
 * @param {Array} files
 */
const CreditsTable = ({ files }) => {
  return (
    <table className="dsw-credits-table">
      <thead>
        <tr>
          <th>Unit</th>
          <th>File Name</th>
          <th className="text-right">Download</th>
        </tr>
      </thead>
      <tbody>
        {files
          && files.map(({ unitNumber, name, url }) => {
            return (
              <tr key={unitNumber}>
                <th scope="row">{unitNumber}</th>
                <td>
                  <img
                    className="dsw-file-pdf-icon"
                    src={FilePdfIcon}
                    alt="pdf file icon"
                  />
                  {name}
                </td>
                <td>
                  <a href={url}>
                    <i className="fa fa-download" />
                  </a>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

CreditsTable.propTypes = {
  files: PropTypes.array.isRequired
};

export default CreditsTable;
