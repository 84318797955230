import React from 'react';
import PropTypes from 'prop-types';
import ActivityDot from '../../../../ActivityDot/ActivityDot';
import mapActivityList from '../../../../../helpers/mapActivityList/mapActivityList';

/**
 * TOC ActivityList component
 * @param {number} activityCount
 * @param {Array<object>} activitiesData
 * @param {number} levelPercentage
 * @returns {Component}
 */
const ActivityList = ({ activityCount, activitiesData, levelPercentage }) => {
  const activityData = mapActivityList(activityCount, activitiesData);

  /**
   * To get last activity of lesson
   * @param {object} activity
   * @returns {boolean}
   */
  const lastActivity = (activity) => {
    return levelPercentage === 100 ? false : activity.isLastActivityInLevel;
  };

  return (
    <>
      {activityData
        && activityData.map((activity, index) => {
          return (
            <ActivityDot
              key={index}
              disabled={!activity.isCompleted}
              showOuterRing={lastActivity(activity)}
            />
          );
        })}
    </>
  );
};

ActivityList.propTypes = {
  activityCount: PropTypes.number.isRequired,
  activitiesData: PropTypes.array.isRequired,
  levelPercentage: PropTypes.number.isRequired
};

export default ActivityList;
