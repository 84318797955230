import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ListView from './ListView/ListView';
import TileView from './TileView/TileView';
import { MY_LIBRARY } from '../../constants/CONSTANTS';
import getStudentLevelDetails from '../../api/services/MyLibraryService/MyLibraryService'; // Importing api service
import mapListOfLevels from '../../helpers/mapListOfLevels/mapListOfLevels';

import mylibraryIcon from '../../assets/images/my-library-icon.png';
import './MyLibrary.css';

import NotificationConfigContext from '../../context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from '../../context/CornerLoaderContext/CornerLoaderContext';
/**
 * MyLibrary component
 */
const MyLibrary = () => {
  const history = useHistory();
  const userState = JSON.parse(localStorage.getItem('UserState'));
  const studentId = userState.userId;
  const currentLevel = (userState.studentLevelInfo && typeof userState.studentLevelInfo.currentLevel === 'string') ? userState.studentLevelInfo.currentLevel.replace('L', '') : '1';
  const query = new URLSearchParams(useLocation().search);
  const [levelData, setLevelData] = useState([]);
  const type = query.get('viewtype') || 'tile';
  const [viewType, setViewType] = useState();
  const notificationContext = useContext(NotificationConfigContext);
  const loaderContext = useContext(CornerLoaderContext);

  useEffect(() => {
    if (userState.isStaffView) {
      setLevelData(mapListOfLevels({}, userState.isStaffView));
    } else {
      getStudentLevelDetails(studentId, notificationContext, loaderContext)
        .then((apiListData) => {
          setLevelData(mapListOfLevels(apiListData, userState.isStaffView));
        });
    }
  }, []);

  useEffect(() => {
    setViewType(type);
  }, [type]);

  /**
   * To change mylibrary view
   * @param {string} type
   */
  const viewTypeHandler = (viewtype) => {
    history.push({
      pathname: '/mylibrary',
      search: `?viewtype=${viewtype}`
    });
    setViewType(viewtype);
  };

  return (
    <div className="mdc-layout-grid no-padding dsw-mylibrary">
      <div className="mdc-layout-grid__inner">
        <div className="mdc-layout-grid__cell--span-4-phone
          mdc-layout-grid__cell--span-8-tablet
          mdc-layout-grid__cell--span-12-desktop"
        >
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-2-phone
              mdc-layout-grid__cell--span-4-tablet
              mdc-layout-grid__cell--span-6-desktop
              mdc-layout-grid--align-left"
            >
              <div className="d-flex">
                <img
                  className="dsw-mylibrary-icon"
                  src={mylibraryIcon}
                  alt="mylibrary icon"
                />
                <h4
                  className="dsw-mylibrary-title"
                  data-testid="my-library-title"
                >
                  {MY_LIBRARY}
                </h4>
              </div>
            </div>
            <div className="mdc-layout-grid__cell--span-2-phone
              mdc-layout-grid__cell--span-4-tablet
              mdc-layout-grid__cell--span-6-desktop
              mdc-layout-grid--align-right
              mdc-layout-grid__cell--align-middle"
            >
              <i
                className={`fa fa-th-large ${viewType === 'tile' ? 'fa-active' : ''}`}
                aria-hidden="true"
                onClick={() => { return viewTypeHandler('tile'); }}
              />
              <i
                className={`fa fa-list-ul ${viewType === 'list' ? 'fa-active' : ''}`}
                aria-hidden="true"
                onClick={() => { return viewTypeHandler('list'); }}
              />
            </div>
          </div>
        </div>
        <div className={`mdc-layout-grid__cell--span-4-phone
          mdc-layout-grid__cell--span-8-tablet
          mdc-layout-grid__cell--span-12-desktop
          ${viewType !== 'list' ? 'position-relative' : ''}`}
        >
          {viewType === 'list' ? (
            <ListView levelData={levelData} studentId={studentId} />
          ) : (
            levelData.length > 0
            && <TileView levelData={levelData} studentId={studentId} currentLevel={currentLevel} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyLibrary;
