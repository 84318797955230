import React from 'react';
import PropTypes from 'prop-types';

import './ActivityDot.css';

/**
 * ActivityDot component
 * @param {boolean} showOuterRing
 * @param {boolean} disabled
 * @returns {Component}
 */
const ActivityDot = ({ showOuterRing, disabled }) => {
  const displayOuterRing = showOuterRing ? 'display' : '';
  const isActive = !disabled ? 'display' : '';

  return (
    <div className={`margin-right-5 outer-ring ${displayOuterRing}`}>
      <span className={`inner-ring ${isActive}`} />
    </div>
  );
};

ActivityDot.propTypes = {
  showOuterRing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ActivityDot;
