import { apiRequest } from '../../../helpers/apiRequest/apiRequest';
import { appLogout } from '../../../helpers/getAuthorizedState/getAuthorizedState';
import { rollbar, logErrorFromPromise } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get student level details
 * @param {string} studentId
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @returns {Promise<object>}
 */
const getStudentLevelDetails = (studentId, notificationContext, loaderContext) => {
  loaderContext.enableLoader(true);
  const url = `${process.env.REACT_APP_DSW_API_URL}student/${studentId}/levelaccess`;
  return apiRequest('GET', url).then((response) => {
    loaderContext.enableLoader(false);
    if (response && response.status === 401) {
      appLogout();
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response.json();
  })
    .catch((response) => {
      loaderContext.enableLoader(false);
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      rollbar.error(response);
    });
};

export default getStudentLevelDetails;
