import React from 'react';
import PropTypes from 'prop-types';

import './BookDescription.css';
import { ISBN_NUMBER } from '../../../constants/CONSTANTS';

/**
 * BookDescription component
 * @param {number} level
 * @returns {Component}
 */
const BookDescription = ({ level }) => {
  const currentYear = new Date().getFullYear();
  const levelISBN = ISBN_NUMBER[`CC_STUDENT_MANUAL_LEVEL${level}`];
  return (
    <div className="mdc-layout-grid__cell--span-12">
      <div className="dsw-book-description">
        <p className="dsw-copy-rights">
          @ 2005-
          {currentYear}
          {' '}
          by WSE Hong Kong Limited.
          <br />
          Edited in Spain, the USA, and Poland.
          <br />
          <span>
            All rights reserved. No part of this publication may be reproduced,
            stored in a retrieval system, or transmitted in any form or by any
            means, electronic, mechanical, photocopying, recording or otherwise
            without the permission of the Publishers.
          </span>
          This material is the intellectual property of WSE Hong Kong Limited.
          <br />
          <span>{`ISBN: ${levelISBN}`}</span>
          This is internal material for students&apos; use only.
          <br />
        </p>
        <p className="no-margin">Dictionaries</p>
        <p className="no-margin">© 2008 by Longman Wordwise Dictionary, 2nd Edition.</p>
        <p className="no-margin">© 2009 by Longman Dictionary of Contemporary English, 5th Edition.</p>
        <p>© 2010 by Longman Active Study Dictionary, 5th Edition.</p>
        <p className="no-margin">www.wallstreetenglish.com</p>
        <p className="no-margin">www.wallstreetenglish.cn</p>
      </div>
    </div>
  );
};

BookDescription.propTypes = {
  level: PropTypes.number.isRequired
};

export default BookDescription;
