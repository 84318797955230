import React from 'react';
import Rollbar from 'rollbar';
import PropTypes from 'prop-types';
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '59caafceed6546d68f8cac7ed018affa',
  environment: process.env.environment,
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoreDuplicateErrors: false
};

export const rollbar = new Rollbar(rollbarConfig);

/**
 * Function to log error by resolving promise
 * @param {promise} response
 */
export const logErrorFromPromise = (response) => {
  response.then((message) => {
    rollbar.error(response, JSON.stringify(message));
  });
};

/**
 * Rollbar configuration component
 * @param {element} children
 */
export const RollbarConfig = ({ children }) => {
  return (
    <Provider instance={rollbar} config={rollbarConfig}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};

RollbarConfig.propTypes = {
  children: PropTypes.element.isRequired
};
