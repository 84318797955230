import getStudentLevelInfo from '../../api/services/lsApiservice/lsApiService';
import { getUserInfo } from '../../api/services/idamApiService/idamApiService';

/**
 * To set student level info
 * @param {object} levelInfo
 */
const setStudentlevelInfo = (levelInfo) => {
  const userState = JSON.parse(localStorage.getItem('UserState'));
  userState.studentLevelInfo = levelInfo.studentLevelInfo;
  localStorage.setItem('UserState', JSON.stringify(userState));
};

/**
 * To check staff accessing student
 * @param {string} userId
 * @param {object} userInfo
 * @returns
 */
const checkStaffAccessStudent = (userId, userInfo) => {
  return ((userInfo.userTypes.indexOf('staff') > -1) && userId !== userInfo.ssdsRefId) || false;
};

/**
 * To set user details
 * @param {string} userId
 * @param {context} initialLoadingContext
 * @param {context} notificationContext
 * @param {context} loaderContext
 */
const setUserDetails = (userId, initialLoadingContext, notificationContext, loaderContext) => {
  getUserInfo(notificationContext, loaderContext).then((userInfo) => {
    const userState = {
      userId,
      studentLevelInfo: null,
      studentFullName: userInfo.fullName,
      isStaffView: (userInfo.userTypes.indexOf('staff') > -1),
      isStaffToStudent: checkStaffAccessStudent(userId, userInfo)
    };
    localStorage.setItem('UserState', JSON.stringify(userState));
    if (userInfo.userTypes.indexOf('Student') > -1) {
      getStudentLevelInfo(userId, notificationContext, loaderContext).then((levelInfo) => {
        setStudentlevelInfo(levelInfo);
        initialLoadingContext.setInitialLoadingHandler(true);
      });
    } else {
      initialLoadingContext.setInitialLoadingHandler(true);
    }
  });
};

export default setUserDetails;
