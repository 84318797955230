import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReviewEditIcon from '../../../../assets/images/svg/review-edit.svg';

import { getReviewLesson } from '../../../../api/services/reviewService/reviewService';
import NotificationConfigContext from '../../../../context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from '../../../../context/CornerLoaderContext/CornerLoaderContext';

import mapActivityList from '../../../../helpers/mapActivityList/mapActivityList';

import './LessonReview.css';

/**
 * TOC LessonReview component
 * @param {number} LessonId
 * @param {function} launchPlayer
 */
const LessonReview = ({ LessonId, launchPlayer }) => {
  const { studentid } = useParams();
  const notificationContext = useContext(NotificationConfigContext);
  const loaderContext = useContext(CornerLoaderContext);

  /**
   * To get mapped review lesson
   * @param {object} reviewUnit
   * @returns {object}
   */
  const getMappedReviewLesson = (reviewUnit) => {
    const reviewLesson = reviewUnit.units[0].lessons[0];
    const activityData = mapActivityList(reviewLesson.activityCount, reviewLesson.activities);
    reviewLesson.activities = activityData;
    return reviewLesson;
  };

  /**
   * To handle review click
   */
  const reviewClickHandler = (event) => {
    event.stopPropagation();
    getReviewLesson(studentid, LessonId, notificationContext, loaderContext).then((reviewUnit) => {
      return getMappedReviewLesson(reviewUnit);
    })
      .then((mappedReviewLesson) => {
        return launchPlayer(mappedReviewLesson, true);
      });
  };

  return (
    <div className="dsw-review-block">
      <div
        className="dsw-review"
        onClick={reviewClickHandler}
      >
        <img
          className="margin-right-5"
          src={ReviewEditIcon}
          alt="Review Edit Icon"
        />
        <label className="dsw-review">Review</label>
      </div>
    </div>
  );
};

LessonReview.propTypes = {
  LessonId: PropTypes.string.isRequired,
  launchPlayer: PropTypes.func.isRequired
};

export default LessonReview;
