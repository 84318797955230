import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from './components/Header/header';
import Footer from './components/Footer/Footer';

import { appLogout } from './helpers/getAuthorizedState/getAuthorizedState';

import './styles/main.css';

/**
 * Function to route with authorization
 * @param {boolean} isAuth
 * @param {Component} component
 * @param {boolean} isPlayer
 * @param {object} rest
 */
const AuthorizedRoute = ({
  isAuth, component: Component, isPlayer, ...rest
}) => {
  /**
   * To render component by condition
   */
  const renderComponent = () => {
    if (isAuth) {
      return (!isPlayer) ? (
        <div className="app">
          <Header />
          <div className="app-content container">
            <Component />
          </div>
          <Footer />
        </div>
      ) : <Component />;
    }
    return appLogout();
  };

  return (
    <Route
      {...rest}
      render={renderComponent}
    />
  );
};

AuthorizedRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  component: PropTypes.elementType,
  isPlayer: PropTypes.bool
};

export default AuthorizedRoute;
