import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Accordion.css';

/**
 * Accordion component
 * @param {string} title
 * @param {element} children
 */
const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="dsw-accordion-item">
      <div
        className="dsw-accordion-heading"
        onClick={() => { return setIsActive(!isActive); }}
      >
        <div className="dsw-accordion-title">
          {title}
          <i className={`dsw-accordion-arrow fa ${isActive ? 'fa-caret-up' : 'fa-caret-down'}`} />
        </div>
      </div>
      {isActive
        && (
          <div className="dsw-accordion-content">
            {children}
          </div>
        )}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default Accordion;
