/**
 * To add event listeners
 * @param {string} eventType
 * @param {function} listener
 */
export const on = (eventType, listener) => {
  document.addEventListener(eventType, listener);
};

/**
 * To remove event listeners
 * @param {string} eventType
 * @param {function} listener
 */
export const off = (eventType, listener) => {
  document.removeEventListener(eventType, listener);
};
