import { apiRequest } from '../../../helpers/apiRequest/apiRequest';
import { appLogout } from '../../../helpers/getAuthorizedState/getAuthorizedState';
import { rollbar, logErrorFromPromise } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get student end of lesson scores
 * @param {string} studentId
 * @param {string} lessonId
 * @param {context} notificationContext
 * @param {context} loaderContext
 */
export const getEndOfLessonScore = (studentId, lessonId, notificationContext, loaderContext) => {
  loaderContext.enableLoader(true);
  const url = `${process.env.REACT_APP_DSW_API_URL}student/${studentId}/lesson/${lessonId}/progress`;

  return apiRequest('GET', url).then((response) => {
    loaderContext.enableLoader(false);
    if (response && response.status === 401) {
      appLogout();
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response.json();
  }).catch((response) => {
    loaderContext.enableLoader(false);
    notificationContext.setNotificationState({
      type: NOTIFICATION_TYPE.ATTENTION,
      title: SOMETHING_WENT_WRONG,
      message: ''
    });
    rollbar.error(response);
  });
};

/**
 * To save student has completed the end of lesson
 * @param {string} studentId
 * @param {string} lessonId
 * @param {context} notificationContext
 */
export const saveEndOfLessonCompleted = async (studentId, lessonId, notificationContext) => {
  try {
    const url = `${process.env.REACT_APP_DSW_API_URL}student/${studentId}/lesson/${lessonId}/complete`;

    const response = await apiRequest('POST', url);
    if (response && response.status === 401) {
      appLogout();
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response;
  } catch (response) {
    notificationContext.setNotificationState({
      type: NOTIFICATION_TYPE.ATTENTION,
      title: SOMETHING_WENT_WRONG,
      message: ''
    });
    return rollbar.error(response);
  }
};
