/**
 * Function to get list of all the lesson in a level
 * @param {object} level
 * @returns {Array}
 */
export const getLessonsInLevel = (level) => {
  const lessonData = [];

  level.units.forEach((unit) => {
    lessonData.push(...unit.lessons);
  });

  return lessonData;
};

/**
 * Function to get the lesson details based on last activity in the level flag
 * @param {object} level
 * @returns {object}
 */
const getLastLessonInLevel = (level) => {
  const lessons = getLessonsInLevel(level);

  return lessons.find((lesson) => {
    return lesson.activities.some((activity) => { return activity.isLastActivityInLevel; });
  });
};

export default getLastLessonInLevel;
