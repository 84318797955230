import React, { useState, useEffect } from 'react';
import AppRoute from './App.route';
import Notification from './components/Notification/Notification';
import CornerLoader from './components/CornerLoader/CornerLoader';
import { RollbarConfig } from './components/RollbarConfig/RollbarConfig';
import NotificationConfigContext from './context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from './context/CornerLoaderContext/CornerLoaderContext';
import InitialLoadingContext from './context/InitialLoadingContext/InitialLoadingContext';
import GTM_SCRIPT from './constants/GTM_SCRIPT';
import MICROSOFT_CLARITY_SCRIPT from './constants/MICROSOFT_CLARITY_SCRIPT';

/**
 * App component
 */
const App = () => {
  const [notificationConfig, setNotificationConfig] = useState();
  const [loader, setLoader] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  /**
   * To set notification config
   * @param {object} config
   */
  const setNotificationState = (config) => {
    setNotificationConfig(config);
  };

  /**
   * To set loader state
   * @param {object} state
   */
  const enableLoader = (state) => {
    setLoader(state);
  };

  /**
   * To set initial loading state
   * @param {boolean} state
   */
  const setInitialLoadingHandler = (state) => {
    setInitialLoading(state);
  };

  useEffect(() => {
    // Check environment and inject GTM script into <head> if needed
    if (process.env.REACT_APP_GTM_IS_ENABLED === 'true') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = GTM_SCRIPT;
      document.head.appendChild(script);
    }
    if (process.env.MICROSOFT_CLARITY_IS_ENABLED) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = MICROSOFT_CLARITY_SCRIPT;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <RollbarConfig>
      <CornerLoaderContext.Provider value={{ loader, enableLoader }}>
        <NotificationConfigContext.Provider value={{ notificationConfig, setNotificationState }}>
          <InitialLoadingContext.Provider value={{ initialLoading, setInitialLoadingHandler }}>
            <AppRoute />
            <Notification />
            <CornerLoader />
          </InitialLoadingContext.Provider>
        </NotificationConfigContext.Provider>
      </CornerLoaderContext.Provider>
    </RollbarConfig>
  );
};

export default App;
