import React from 'react';

/**
 * CookiePolicies component
 */
const CookiePolicies = () => {
  return (
    <>
      <p className="wse-typo-body policy-updated-date">(Updated February 1,2018)</p>
      <p className="wse-typo-body">We use cookies (a small piece of computer code that we store for a period of time on your computer or mobile device) when you visit our sites in order to make the sites work better for you. The data we collect using cookies helps us understand our customers better so that we can provide a more focused user experience. Using the knowledge of your previous visits to our sites, we can enhance subsequent visits by tailoring our content to match your requirements. We use cookies principally because we want to make our sites and mobile applications user-friendly, and we are interested in anonymous user behaviour. Generally, our cookies don't store sensitive or personally identifiable information such as your name and address or credit card details. In the unlikely event that our sites use cookies to store personal information about you, we encrypt the personal data to prevent unauthorised use by anyone else. Here's a list of the main types of cookies we use, and what we use them for.
      </p>

      <p className="wse-typo-body"></p>

      <p className="wse-typo-body">1.
        <strong>Strictly Necessary Cookies</strong>: These cookies are essential in order to enable you to move around the site and use its features, such as accessing secure areas of the site. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided. As these cookies are strictly necessary, we do not need to ask for your consent to use them.</p>

      <p className="wse-typo-body">2.
        <strong>Performance Cookies</strong>: These cookies collect information about how visitors use a site, for instance which pages visitors go to most often, and if they get error messages from web pages. Some of these are analytics cookies, set using third-party web analytics software, which allow us to understand more about how our site is used.
      </p>

      <p className="wse-typo-body">For example, like many companies, we use Google Analytics, a web analytics service provided by Google, Inc., to help us monitor our site traffic. Google Analytics uses “cookies”, which are text files placed on your computer, to help the site analyse how users use the site. The information generated by the cookie about your use of the site (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the site, compiling reports on site activity for site operators and providing other services relating to site activity and Internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this site. By using this site, you consent to the processing of data about you by Google in the manner and for the purposes set out above. To opt out of being tracked by Google Analytics across all sites visit
        <a target="_blank" href="http://tools.google.com/dlpage/gaoptout"> http://tools.google.com/dlpage/gaoptout</a>.
      </p>

      <p className="wse-typo-body">Nevertheless, performance cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous and it does not affect your privacy. It is only used to improve how a site works. We might also use these cookies to highlight our products or services which we think will be of interest to you based on your use of our sites.
        <strong>By using Wall Street English’s sites you agree we may place these types of cookies on your device.</strong></p>

      <p className="wse-typo-body">3.
        <strong>Functionality Cookies</strong>: These cookies allow the site to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. For instance, these cookies can be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The
        information these cookies collect may be anonymised and they cannot track your browsing activity on other sites.
        <strong>By using Wall Street English’s sites you agree we may place these types of cookies on your device.</strong></p>

      <p className="wse-typo-body">4.
        <strong>Targeting or advertising cookies</strong>: These cookies are used to deliver adverts more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the site operator’s permission. They remember that you have visited a site and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organisation.
        <strong>Our Sites do not use targeting cookies. We will ask for your consent before we use targeting cookies.</strong></p>

      <p className="wse-typo-body">Wall Street English’s cookies typically contain:</p>

      <ul className="wse-typo-body">
        <li>A unique name set by the site that generated it</li>
        <li>A value – the information within it that recalls your previous activities</li>
        <li>An expiration date, which determines how long the cookie will remain active in your browser before being automatically deleted.
        </li>
      </ul>

      <p className="wse-typo-body">Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit
        <a target="_blank" href="http://www.aboutcookies.org"> http://www.aboutcookies.org </a>
        or
        <a target="_blank" href="http://www.allaboutcookies.org"> http://www.allaboutcookies.org</a>
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Third party cookies and social networks</strong>
      </p>

      <p className="wse-typo-body">If you click on a hyperlink from Wall Street English’s sites to any third-party sites (e.g. if you 'share' content from Wall Street English’s sites with friends or colleagues through social networks), you may be sent cookies from these third-party sites. Third-party sites will have their own privacy and cookie policies which Wall Street English cannot control. Please check the third-party sites for more information about their cookies and how to manage them.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Do we track whether users open our emails?</strong>
      </p>

      <p className="wse-typo-body">Our emails may contain a “web beacon pixel” to tell us whether our emails are opened and verify any clicks through to links or advertisements within the email. We may use this information for purposes including determining which of our emails are more interesting to users, and to query whether users who do not open our emails wish to continue receiving them. The pixel will be deleted when you delete the email. If you do not wish the pixel to be downloaded to your device, you should read the email in plain text view or with images disabled.
      </p>
    </>
  );
};

export default CookiePolicies;
