/**
 * To call rest api
 * @param {string} type
 * @param {string} url
 * @param {...object} rest
 */
export const apiRequest = (type, url, rest) => {
  const accessToken = `Bearer ${localStorage.getItem('Auth')}`;
  return fetch(url, {
    method: type,
    headers: {
      authorization: accessToken,
      'Content-Type': 'application/json, text/plain, */*'
    },
    ...rest
  });
};

/**
 * To call rest api without authentication
 * @param {string} type
 * @param {string} url
 * @param {...object} rest
 */
export const apiRequestWithoutAuth = (type, url, rest) => {
  return fetch(url, {
    method: type,
    headers: {
      'Content-Type': 'application/json, text/plain, */*'
    },
    ...rest
  });
};

/**
 * To call rest api with default header
 * @param {string} type
 * @param {string} url
 * @param {...object} rest
 */
export const apiRequestWithDefaultHeader = (type, url, rest) => {
  return fetch(url, {
    method: type,
    ...rest
  });
};
