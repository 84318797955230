import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImageCredits from '../ImageCredits/ImageCredits';
import PoliciesModal from '../PoliciesModal/PoliciesModal';
import PrivacyPolicies from '../PoliciesModal/PrivacyPolicies/PrivacyPolicies';
import CookiePolicies from '../PoliciesModal/CookiePolicies/CookiePolicies';
import TermsOfUse from '../PoliciesModal/TermsOfUse/TermsOfUse';
import { on, off } from '../../../helpers/eventListeners/eventListeners';
import { PRIVACY_POLICY_CHINA } from '../../../constants/CONSTANTS';

/**
 * To handle policies
 */
const PoliciesHandlerComponent = ({ content, closeContentHandler }) => {
  const isChina = (process.env.REACT_APP_IS_CHINA_APP === 'true');

  /**
   * To close modal on esc
   */
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeContentHandler();
    }
  }, []);

  useEffect(() => {
    on('keydown', escFunction);
    return () => {
      off('keydown', escFunction);
    };
  }, []);

  switch (content) {
    case 'privacy':
      return (
        <PoliciesModal title={isChina ? PRIVACY_POLICY_CHINA : 'PRIVACY POLICY'} isOpen closeContentHandler={closeContentHandler}>
          <PrivacyPolicies />
        </PoliciesModal>
      );
    case 'cookie':
      return (
        <PoliciesModal title="COOKIE POLICY" isOpen closeContentHandler={closeContentHandler}>
          <CookiePolicies />
        </PoliciesModal>
      );
    case 'terms-of-use':
      return (
        <PoliciesModal title="TERMS OF USE" isOpen closeContentHandler={closeContentHandler}>
          <TermsOfUse />
        </PoliciesModal>
      );
    case 'image-credits':
      return (
        <ImageCredits isOpen closeContentHandler={closeContentHandler} />
      );
    default:
      return null;
  }
};

PoliciesHandlerComponent.propTypes = {
  content: PropTypes.string.isRequired,
  closeContentHandler: PropTypes.func.isRequired
};

export default PoliciesHandlerComponent;
