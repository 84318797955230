import React, { useContext } from 'react';
import CornerLoaderContext from '../../context/CornerLoaderContext/CornerLoaderContext';

import './CornerLoader.css';

/**
 * CornerLoader component
 */
const CornerLoader = () => {
  const loaderContext = useContext(CornerLoaderContext);

  return (
    <div className={`corner-loader ${loaderContext.loader ? 'corner-loader-animate' : ''}`}>
      <div className="circle-loader" />
    </div>
  );
};

export default CornerLoader;
