import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Importing styles
import DropDownStyles from './DropDown.module.css';

/**
 * Drop Down component
 * @param {string} selectedItem
 * @param {Array} items
 * @param {function} onClickHandler
 * @returns {element}
 */
const DropDown = ({ selectedItem, items, onClickHandler }) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Function to toggle the drop down menu state
   */
  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Function to send the select item info to function from props
   * @param {object} item
   */
  const changeHandler = (item) => {
    setIsOpen(!isOpen);
    onClickHandler(item);
  };

  return (
    <div className={DropDownStyles.dropDownContainer}>
      <div className={`dropdown ${DropDownStyles.dropDown} ${(isOpen) ? DropDownStyles.open : ''}`}>
        <button
          className={DropDownStyles.dropDownToggle}
          type="button"
          data-toggle="dropdown"
          data-testid="drop-down-button"
          aria-expanded="true"
          onClick={toggleDropDown}
        >
          {selectedItem}
          <span className="fa fa-angle-down" />
        </button>
        <ul
          className={DropDownStyles.dropDownMenu}
          role="menu"
          data-testid="drop-down-menu"
        >
          {items && items.map((item) => {
            return (
              <li
                key={item.language_code}
                role="presentation"
                className={DropDownStyles.dropDownItem}
              >
                <a
                  id={item.language_code}
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => { return changeHandler(item); }}
                  data-testid={`drop-down-menu-item-${item.language_code}`}
                >
                  <span className={item.isDefault ? 'fa fa-check' : ''} />
                  <span>{item.language_name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

DropDown.propTypes = {
  selectedItem: PropTypes.string,
  items: PropTypes.array.isRequired,
  onClickHandler: PropTypes.func
};

export default DropDown;
