import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../ModalDialog/ModalDialog';
import Accordion from '../../Accordion/Accordion';
import CreditsTable from './CreditsTable/CreditsTable';
import Pagination from '../../Pagination/Pagination';
import credits from '../../../constants/IMAGECREDITS';

import './ImageCredits.css';

/**
 * Image credits component
 * @param {boolean} isOpen
 * @param {Function} closeContentHandler
 */
const ImageCredits = ({ isOpen, closeContentHandler }) => {
  const [openModal, setOpenModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCredits, setCurrentCredits] = useState(null);
  const perPage = 8;

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const indexOfLastCredits = currentPage * perPage;
    const indexOfFirstCredits = indexOfLastCredits - perPage;
    setCurrentCredits(credits.slice(indexOfFirstCredits, indexOfLastCredits));
  }, [currentPage]);

  /**
   * To close Modal
   */
  const closeHandler = () => {
    closeContentHandler();
    setOpenModal(false);
  };

  /**
   * To handle pagination
   * @param {number} selectedPage
   */
  const onPageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <>
      {openModal
        && (
        <ModalDialog isOpen={openModal}>
          <div className="dsw-footer-credits">
            <div className="dsw-modal-header">
              <h3 className="dsw-modal-title">IMAGE CREDITS</h3>
              <button
                className="close-modal"
                type="button"
                onClick={closeHandler}
              >
                <i className="fa fa-times-thin fa-2x" aria-hidden="true" />
              </button>
            </div>
            <div className="dsw-modal-body">
              <div className="dsw-credits-accordion">
                {currentCredits
                  && currentCredits.map((item, index) => {
                    return (
                      <Accordion key={index} title={item.name}>
                        <CreditsTable key={index} files={item.files} />
                      </Accordion>
                    );
                  })}
              </div>
              <div className="dsw-credits-pagination">
                <Pagination
                  totalData={credits.length}
                  perPage={perPage}
                  onPageClickHandler={onPageClick}
                />
              </div>
            </div>
            <div className="dsw-modal-footer">
              <button
                className="close-btn"
                type="button"
                onClick={closeHandler}
              >
                Close
              </button>
            </div>
          </div>
        </ModalDialog>
        )}
    </>
  );
};

ImageCredits.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeContentHandler: PropTypes.func.isRequired
};

export default ImageCredits;
