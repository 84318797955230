import React from 'react';
import PropTypes from 'prop-types';

import './CircleProgressBar.css';

/**
 * CircularProgressBar component
 * @param {object} props
 */
const CircularProgressBar = (props) => {
  const circleactive = props.circleactive;
  const showpercentage = props.showpercentage;
  const percentage = props.percentage;
  const strokeWidth = props.strokeWidth;
  // Size of the enclosing square
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - dashArray * percentage / 100;

  return (
    <>
      <svg
        data-testid={`${circleactive ? 'enabled' : 'disabled'}-progress-ring`}
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}
      >
        <circle
          className={circleactive ? 'circle-background' : 'circle-background-disable'}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
        />
        <text
          className={`circle-text ${(showpercentage) ? 'd-block' : 'd-none'}`}
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${percentage}%`}
        </text>
      </svg>
    </>
  );
};

CircularProgressBar.propTypes = {
  props: PropTypes.object
};

export default CircularProgressBar;
