import React from 'react';
import PropTypes from 'prop-types';
import LessonList from './LessonList/LessonList';
import CircularProgressBar from '../../CircleProgressBar/CircleProgressBar';
import UnitCompletedIcon from '../../../assets/images/svg/unit-completed-icon.svg';

import './UnitList.css';

/**
 * TOC UnitList component
 * @param {object} levelData
 * @returns {Component}
 */
const UnitList = ({ levelData }) => {
  const unitsData = levelData.units;
  return (
    <div className="mdc-layout-grid__cell--span-12 dsw-units">
      <div className="mdc-layout-grid__inner">
        {unitsData
          && unitsData.map((unit, index) => {
            return (
              <div className="mdc-layout-grid__cell--span-12 dsw-units-rectangle" key={index}>
                <div className="dsw-unit-container">
                  <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell--span-1-phone
                    mdc-layout-grid__cell--span-1-tablet
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--align-middle"
                    >
                      <div className="dsw-unit-progress">
                        {unit.completionPercentage === 100 ? (
                          <img
                            src={UnitCompletedIcon}
                            alt="Unit completed icon"
                          />
                        ) : (
                          unit.completionPercentage > 0 && (
                          <CircularProgressBar
                            strokeWidth="3"
                            sqSize="50"
                            percentage={unit.completionPercentage}
                            showpercentage
                          />
                          ))}
                      </div>
                      <h4 className="dsw-unit-title">{`Unit ${unit.unitNumber}`}</h4>
                    </div>
                    <div className="mdc-layout-grid__cell--span-3-phone
                    mdc-layout-grid__cell--span-7-tablet
                    mdc-layout-grid__cell--span-10-desktop
                    margin-top-bottom-35
                    padding-left-15"
                    >
                      <LessonList
                        unitData={unit}
                        levelPercentage={levelData.completionPercentage}
                        reviewMode={levelData.reviewMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

UnitList.propTypes = {
  levelData: PropTypes.object.isRequired
};

export default UnitList;
