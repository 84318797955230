import React from 'react';
import { Link } from 'react-router-dom';

import { Home } from '../../constants/CONSTANTS';
import { HOME_URL } from '../../constants/ROUTECONSTANTS';
import WseLogo from '../../assets/images/svg/logo-wse.svg';
import HomeIcon from '../../assets/images/svg/home-icon.svg';
import './header.css';

/**
 * Header component
 */
const Header = () => {
  return (
    <div className="nav">
      <div className="d-flex no-padding container">
        <div className="nav-menu-left">
          <a
            className="d-flex"
            href={HOME_URL}
          >
            <img
              className="dsw-home-icon"
              src={HomeIcon}
              alt="Home"
            />
            {Home}
          </a>
        </div>
        <div className="nav-menu-center">
          <img
            className="wse-logo"
            src={WseLogo}
            alt="WseLogo"
          />
        </div>
        <div className="nav-menu-right">
          <Link to="/logout">
            <i
              className="fa fa-power-off"
              aria-hidden="true"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
