import { apiRequest } from '../../../helpers/apiRequest/apiRequest';
import { appLogout } from '../../../helpers/getAuthorizedState/getAuthorizedState';
import { rollbar, logErrorFromPromise } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get student level details
 * @param {string} userId
 * @param {string} levelId
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @param {string} lessonId
 */
export const getUserLevelProgress = (
  userId,
  levelId,
  notificationContext,
  loaderContext,
  lessonId
) => {
  const userState = JSON.parse(localStorage.getItem('UserState'));
  loaderContext.enableLoader(true);
  const url = `${process.env.REACT_APP_DSW_API_URL}${(userState.isStaffView && !userState.isStaffToStudent) ? 'staff/' : 'student/'}${userId}/level/${levelId}${(lessonId) ? `?lessonId=${lessonId}` : ''}`;
  return apiRequest('GET', url).then((response) => {
    loaderContext.enableLoader(false);
    if (response && response.status === 401) {
      appLogout();
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response.json();
  }).catch((response) => {
    loaderContext.enableLoader(false);
    notificationContext.setNotificationState({
      type: NOTIFICATION_TYPE.ATTENTION,
      title: SOMETHING_WENT_WRONG,
      message: ''
    });
    rollbar.error(response);
  });
};

/**
 * To set student study mode
 * @param {bool} reviewMode
 * @param {object} contentData
 * @returns {object}
 */
export const setStudentStudyMode = (
  reviewMode, contentData
) => {
  if (!reviewMode) {
    const studyMode = localStorage.getItem('InCenter') ? 1 : 0;
    return {
      ...contentData,
      StudyMode: studyMode
    };
  }
  return contentData;
};

/**
 * To get student activity progress
 * @param {string} studentId
 * @param {string} activityReferenceId
 * @param {bool} reviewMode
 * @param {context} notificationContext
 */
export const getStudentActivityProgress = (
  studentId, activityReferenceId, reviewMode, notificationContext
) => {
  const reviewType = reviewMode ? '/review' : '';
  const url = `${process.env.REACT_APP_DSW_API_URL}student/${studentId}${reviewType}/activity/${activityReferenceId}`;
  return apiRequest('GET', url).then((response) => {
    if (response && response.status === 401) {
      appLogout();
    }
    if (response.status === 204) {
      return null;
    }
    if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
    }
    return response.json();
  }).catch((response) => {
    notificationContext.setNotificationState({
      type: NOTIFICATION_TYPE.ATTENTION,
      title: SOMETHING_WENT_WRONG,
      message: ''
    });
    rollbar.error(response);
  });
};

/**
 * To save student answer
 * @param {string} studentId
 * @param {object} contentData
 * @param {bool} reviewMode
 * @param {context} notificationContext
 */
export const saveStudentAnswer = (studentId, contentData, reviewMode, notificationContext) => {
  const reviewType = reviewMode ? '/review' : '';
  const url = `${process.env.REACT_APP_DSW_API_URL}student/${studentId}${reviewType}/results`;
  contentData = setStudentStudyMode(reviewMode, contentData);
  const requestData = {
    body: JSON.stringify(contentData)
  };

  return apiRequest('POST', url, requestData).then((response) => {
    if (response && response.status === 401) {
      appLogout();
    } else if (response && response.status === 400) {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      logErrorFromPromise(response.json());
      throw response;
    }
  }).catch((error) => {
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      throw error;
    } else {
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      rollbar.error(error);
    }
  });
};
