import React, { useState } from 'react';
import PoliciesHandlerComponent from './PoliciesHandlerComponent/PoliciesHandlerComponent';
import { PRIVACY_POLICY_CHINA } from '../../constants/CONSTANTS';

import './Footer.css';

/**
 * Footer component
 */
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [content, setContent] = useState(null);
  const isChina = (process.env.REACT_APP_IS_CHINA_APP === 'true');

  /**
   * To set content state to null
   */
  const closeContentHandler = () => {
    setContent(null);
  };

  return (
    <>
      <div className="dsw-footer">
        <div className="d-flex no-padding container">
          <p>
            ©
            {currentYear}
            {' '}
            WSE Hong Kong Limited
          </p>
          <p className="pointer" onClick={() => { return setContent('privacy'); }}>{`| ${isChina ? PRIVACY_POLICY_CHINA : 'Privacy Policy'}`}</p>
          <p className="pointer" onClick={() => { return setContent('cookie'); }}>| Cookie Policy</p>
          <p className="pointer" onClick={() => { return setContent('terms-of-use'); }}>| Terms Of Use</p>
          <p className="pointer" onClick={() => { return setContent('image-credits'); }}>| Image Credits</p>
          <p>| wallstreetenglish.com</p>
          <p>|</p>
          <p>24.06.02</p>
        </div>
      </div>
      {content
        && (
          <PoliciesHandlerComponent
            content={content}
            closeContentHandler={closeContentHandler}
          />
        )}
    </>
  );
};

export default Footer;
