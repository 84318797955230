const credits = [
  {
    name: 'Level 1 Core Credits',
    files: [
      {
        unitNumber: 1,
        name: 'WSE NSE Core_PhotoCredits_01_U1.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Core/WSE%20NSE%20Core_PhotoCredits_01_U1.pdf`
      },
      {
        unitNumber: 2,
        name: 'WSE NSE Core_PhotoCredits_01_U2.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Core/WSE%20NSE%20Core_PhotoCredits_01_U2.pdf`
      },
      {
        unitNumber: 3,
        name: 'WSE NSE Core_PhotoCredits_01_U3.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Core/WSE%20NSE%20Core_PhotoCredits_01_U3.pdf`
      },
      {
        unitNumber: 4,
        name: 'WSE NSE Core_PhotoCredits_01_U4.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Core/WSE%20NSE%20Core_PhotoCredits_01_U4.pdf`
      }
    ]
  },
  {
    name: 'Level 1 Assessment Credits',
    files: [
      {
        unitNumber: 1,
        name: 'WSE NSE Assessment_PhotoCredits_01_U1.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Assessment/WSE%20NSE%20Assessment_PhotoCredits_01_U1.pdf`
      },
      {
        unitNumber: 2,
        name: 'WSE NSE Assessment_PhotoCredits_01_U2.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Assessment/WSE%20NSE%20Assessment_%20PhotoCredits_01_U2.pdf`
      },
      {
        unitNumber: 3,
        name: 'WSE NSE Assessment_PhotoCredits_01_U3.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Assessment/WSE%20NSE%20Assessment_PhotoCredits_01_U3.pdf`
      },
      {
        unitNumber: 4,
        name: 'WSE NSE Assessment_PhotoCredits_01_U4.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Assessment/WSE%20NSE%20Assessment_PhotoCredits_01_U4.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE_Ckpoint_PhotoCredits_Level 01.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%201/Assessment/WSE%20NSE_Ckpoint_PhotoCredits_Level%2001.pdf`
      }
    ]
  },
  {
    name: 'Level 1 Workbook Credits',
    files: [
      {
        unitNumber: 1,
        name: 'WSE NSE SM_PhotoCredits_01_U1.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%201/DSW/WSE%20NSE%20SM_PhotoCredits_01_U1.pdf`
      },
      {
        unitNumber: 2,
        name: 'WSE NSE SM_PhotoCredits_01_U2.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%201/DSW/WSE%20NSE%20SM_PhotoCredits_01_U2.pdf`
      },
      {
        unitNumber: 3,
        name: 'WSE NSE SM_PhotoCredits_01_U3.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%201/DSW/WSE%20NSE%20SM_PhotoCredits_01_U3.pdf`
      },
      {
        unitNumber: 4,
        name: 'WSE NSE SM_PhotoCredits_01_U4.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%201/DSW/WSE%20NSE%20SM_PhotoCredits_01_U4.pdf`
      }
    ]
  },
  {
    name: 'Level 2 Core Credits',
    files: [
      {
        unitNumber: 5,
        name: 'WSE NSE PhotoCredits_Core_02_U5.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Core/WSE%20NSE%20PhotoCredits_Core_02_U5.pdf`
      },
      {
        unitNumber: 6,
        name: 'WSE NSE PhotoCredits_Core_02_U6.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Core/WSE%20NSE%20PhotoCredits_Core_02_U6.pdf`
      },
      {
        unitNumber: 7,
        name: 'WSE NSE PhotoCredits_Core_02_U7.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Core/WSE%20NSE%20PhotoCredits_Core_02_U7.pdf`
      },
      {
        unitNumber: 8,
        name: 'NSE PhotoCredits_Core_02_U8.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Core/NSE%20PhotoCredits_Core_02_U8.pdf`
      }
    ]
  },
  {
    name: 'Level 2 Assessment Credits',
    files: [
      {
        unitNumber: 5,
        name: 'WSE NSE PhotoCredits_02_U5_Assessment.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Assessment/WSE%20NSE%20PhotoCredits_02_U5_Assessment.pdf`
      },
      {
        unitNumber: 6,
        name: 'WSE NSE PhotoCredits_02_U6_Assessment.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Assessment/WSE%20NSE%20PhotoCredits_02_U6_Assessment.pdf`
      },
      {
        unitNumber: 7,
        name: 'WSE NSE PhotoCredits_02_U7_Assessment.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Assessment/WSE%20NSE%20PhotoCredits_02_U7_Assessment.pdf`
      },
      {
        unitNumber: 8,
        name: 'WSE NSE PhotoCredits_02 U8 Assessment_.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Assessment/WSE%20NSE%20PhotoCredits_02%20U8%20Assessment_.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE PhotoCredits_Ckpoint_L2.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%202/Assessment/WSE%20NSE%20PhotoCredits_Ckpoint_L2.pdf`
      }
    ]
  },
  {
    name: 'Level 2 Workbook Credits',
    files: [
      {
        unitNumber: 5,
        name: 'WSE NSE PhotoCredits_02_U5_SM.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%202/DSW/WSE%20NSE%20PhotoCredits_02_U5_SM.pdf`
      },
      {
        unitNumber: 6,
        name: 'WSE NSE SM_PhotoCredits_02_U6.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%202/DSW/WSE%20NSE%20SM_PhotoCredits_02_U6.pdf`
      },
      {
        unitNumber: 7,
        name: 'WSE NSE 02_U7_SM PhotoCredits.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%202/DSW/WSE%20NSE%2002_U7_SM PhotoCredits.pdf`
      },
      {
        unitNumber: 8,
        name: 'WSE NSE SM_PhotoCredits_02_U8.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%202/DSW/WSE%20NSE%20SM_PhotoCredits_02_U8.pdf`
      }
    ]
  },
  {
    name: 'Level 3 Core Credits',
    files: [
      {
        unitNumber: 9,
        name: 'WSE NSE Photo Credits, L3, U9, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Core/WSE%20NSE%20Photo%20Credits,%20L3,%20U9,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 10,
        name: 'WSE NSE Photo Credits, L3, U10, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Core/WSE%20NSE%20Photo%20Credits,%20L3,%20U10,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 11,
        name: 'WSE NSE Photo Credits, L3, U11, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Core/WSE%20NSE%20Photo%20Credits,%20L3,%20U11,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 12,
        name: 'WSE NSE Photo Credits, L3, U12, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Core/WSE%20NSE%20Core_PhotoCredits_03_U12.pdf`
      }
    ]
  },
  {
    name: 'Level 3 Assessment Credits',
    files: [
      {
        unitNumber: 9,
        name: 'WSE NSE Photo Credits, L3, U9, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Assessment/WSE%20NSE%20Photo%20Credits,%20L3,%20U9,%20AS_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 10,
        name: 'WSE NSE Photo Credits, L3, U10, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Assessment/WSE%20NSE%20Photo%20Credits,%20L3,%20U10,%20AS_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 11,
        name: 'WSE NSE Photo Credits, L3, U11, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Assessment/WSE%20NSE%20Photo%20Credits,%20L3,%20U11,%20AS_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 12,
        name: 'WSE NSE Photo Credits, L3, U12, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Assessment/WSE%20NSE%20Photo%20Credits,%20L3,%20U12,%20AS_HH%2007.26.15%20(1).pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L3, CkPt_HH 07.26.15 (1).pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%203/Assessment/WSE%20NSE%20Photo%20Credits,%20L3,%20CkPt_HH%2007.26.15%20(1).pdf`
      }
    ]
  },
  {
    name: 'Level 3 Workbook Credits',
    files: [
      {
        unitNumber: 9,
        name: 'WSE NSE SM_PhotoCredits_03_U09_HH 07.24.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%203/DSW/WSE%20NSE%20SM_PhotoCredits_03_U09_HH%2007.24.15.pdf`
      },
      {
        unitNumber: 10,
        name: 'WSE NSE SM_PhotoCredits_03_U10.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%203/DSW/WSE%20NSE%20SM_PhotoCredits_03_U10.pdf`
      },
      {
        unitNumber: 11,
        name: 'WSE NSE SM_PhotoCredits_03_U11.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%203/DSW/WSE%20NSE%20SM_PhotoCredits_03_U11.pdf`
      },
      {
        unitNumber: 12,
        name: 'WSE NSE SM_PhotoCredits_03_U12.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%203/DSW/WSE%20NSE%20SM_PhotoCredits_03_U12.pdf`
      }
    ]
  },
  {
    name: 'Level 4 Core Credits',
    files: [
      {
        unitNumber: 13,
        name: 'WSE NSE Photo Credits, L4, U13, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Core/WSE%20NSE%20Photo%20Credits,%20L4,%20U13,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 14,
        name: 'WSE NSE Photo Credits, L4, U14, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Core/WSE%20NSE%20Photo%20Credits,%20L4,%20U14,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 15,
        name: 'WSE NSE Photo Credits, L4, U15, CA_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Core/WSE%20NSE%20Photo%20Credits,%20L4,%20U15,%20CA_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 16,
        name: 'WSE NSE Photo Credits, L4, U16, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Core/WSE%20NSE%20Core_PhotoCredits_04_U16.pdf`
      }
    ]
  },
  {
    name: 'Level 4 Assessment Credits',
    files: [
      {
        unitNumber: 13,
        name: 'WSE NSE Photo Credits, L4, U13, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Assessment/WSE%20NSE%20Photo%20Credits,%20L4,%20U13,%20AS_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 14,
        name: 'WSE NSE Photo Credits, L4, U14, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Assessment/WSE%20NSE%20Photo%20Credits,%20L4,%20U14,%20AS_HH%2007.26.pdf`
      },
      {
        unitNumber: 15,
        name: 'WSE NSE Photo Credits, L4, U15, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Assessment/WSE%20NSE%20Photo%20Credits,%20L4,%20U15,%20AS_HH%2007.26.15.pdf`
      },
      {
        unitNumber: 16,
        name: 'WSE NSE Photo Credits, L4, U16, AS_HH 07.26.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%204/Assessment/WSE%20NSE%20Photo%20Credits,%20L4,%20U16,%20AS_HH%2007.26.15.pdf`
      }
    ]
  },
  {
    name: 'Level 4 Workbook Credits',
    files: [
      {
        unitNumber: 14,
        name: 'WSE NSE SM_PhotoCredits_04_U14.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%204/DSW/WSE%20NSE%20SM_PhotoCredits_04_U14.pdf`
      },
      {
        unitNumber: 15,
        name: 'WSE NSE SM_PhotoCredits_04_U15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%204/DSW/WSE%20NSE%20SM_PhotoCredits_04_U15.pdf`
      },
      {
        unitNumber: 16,
        name: 'WSE NSE SM_PhotoCredits_04_U16.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%204/DSW/WSE%20NSE%20SM_PhotoCredits_04_U16.pdf`
      }
    ]
  },
  {
    name: 'Level 5 Core Credits',
    files: [
      {
        unitNumber: 17,
        name: 'WSE NSE Photo Credits, L5, U17, CA_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Core/WSE%20NSE%20Core_%20Photo%20Credits_05_U17.pdf`
      },
      {
        unitNumber: 18,
        name: 'WSE NSE Photo Credits, L5, U18, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Core/WSE%20NSE%20Core_PhotoCredits_05_U18.pdf`
      },
      {
        unitNumber: 19,
        name: 'WSE NSE Photo Credits, L5, U19, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Core/WSE%20NSE%20Core_PhotoCredits_05_U19.pdf`
      },
      {
        unitNumber: 20,
        name: 'WSE NSE Photo Credits, L5, U20, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Core/WSE%20NSE%20Core_PhotoCredits_05_U20.pdf`
      }
    ]
  },
  {
    name: 'Level 5 Assessment Credits',
    files: [
      {
        unitNumber: 16,
        name: 'WSE NSE Photo Credits, L5, CP_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Assessment/WSE%20NSE%20Photo%20Credits,%20L5,%20CP_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 17,
        name: 'WSE NSE Photo Credits, L5, U17, AS_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Assessment/WSE%20NSE%20Photo%20Credits,%20L5,%20U17,%20AS_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 18,
        name: 'WSE NSE Photo Credits, L5, U18, AS_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%205/Assessment/WSE%20NSE%20Photo%20Credits,%20L5,%20U18,%20AS_HH%2008.16.15.pdf`
      }
    ]
  },
  {
    name: 'Level 5 Workbook Credits',
    files: [
      {
        unitNumber: 17,
        name: 'WSE NSE Photo Credits, L5, U17, SM_HH_nl 10-13.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%205/DSW/WSE%20NSE%20Photo%20Credits,%20L5,%20U17,%20SM_HH_nl%2010-13.15.pdf`
      },
      {
        unitNumber: 18,
        name: 'WSE NSE Photo Credits, L5, U18, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%205/DSW/WSE%20NSE%20Photo%20Credits,%20L5,%20U18,%20SM_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 20,
        name: 'WSE NSE Photo Credits, L5, U20, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%205/DSW/WSE%20NSE%20Photo%20Credits,%20L5,%20U20,%20SM_HH%2008.16.15.pdf`
      }
    ]
  },
  {
    name: 'Level 6 Core Credits',
    files: [
      {
        unitNumber: 21,
        name: 'WSE NSE Photo Credits, L6, U21, CA_HH 17.22.06_NL 8-26.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Core/WSE%20NSE%20Core_PhotoCredits_06_U21.pdf`
      },
      {
        unitNumber: 22,
        name: 'WSE NSE Photo Credits, L6, U22, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Core/WSE%20NSE%20Core_PhotoCredits_06_U22.pdf`
      },
      {
        unitNumber: 23,
        name: 'WSE NSE Photo Credits, L6, U23, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Core/WSE%20NSE%20Core_PhotoCredits_06_U23.pdf`
      },
      {
        unitNumber: 24,
        name: 'WSE NSE Photo Credits, L6, U24, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Core/WSE%20NSE%20Core_PhotoCredits_06_U24.pdf`
      }
    ]
  },
  {
    name: 'Level 6 Assessment Credits',
    files: [
      {
        unitNumber: 21,
        name: 'WSE NSE Photo Credits, L6, U21, AS_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Assessment/WSE%20NSE%20Photo%20Credits,%20L6,%20U21,%20AS_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 22,
        name: 'WSE NSE Photo Credits, L6, U22, AS_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Assessment/WSE%20NSE%20Photo%20Credits,%20L6,%20U22,%20AS_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 23,
        name: 'WSE NSE Photo Credits, L6, U23, AS_HH 08.16.15 (1).pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%206/Assessment/WSE%20NSE%20Photo%20Credits,%20L6,%20U23,%20AS_HH%2008.16.15%20(1).pdf`
      }
    ]
  },
  {
    name: 'Level 6 Workbook Credits',
    files: [
      {
        unitNumber: 21,
        name: 'WSE NSE Photo Credits, L6, U21, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%206/DSW/WSE%20NSE%20Photo%20Credits,%20L6,%20U21,%20SM_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 22,
        name: 'WSE NSE Photo Credits, L6, U22, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%206/DSW/WSE%20NSE%20Photo%20Credits,%20L6,%20U22,%20SM_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 23,
        name: 'WSE NSE Photo Credits, L6, U23, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%206/DSW/WSE%20NSE%20Photo%20Credits,%20L6,%20U23,%20SM_HH%2008.16.15.pdf`
      },
      {
        unitNumber: 24,
        name: 'WSE NSE Photo Credits, L6, U24, SM_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%206/DSW/WSE%20NSE%20Photo%20Credits,%20L6,%20U24,%20SM_HH%2008.16.15.pdf`
      }
    ]
  },
  {
    name: 'Level 7 Core Credits',
    files: [
      {
        unitNumber: 25,
        name: 'WSE NSE Photo Credits, L7, U25, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Core/WSE%20NSE%20Photo%20Credits,%20L7,%20U25,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 26,
        name: 'WSE NSE Photo Credits, L7, U26, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Core/WSE%20NSE%20Photo%20Credits,%20L7,%20U26,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 27,
        name: 'WSE NSE Photo Credits, L7, U27, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Core/WSE%20NSE%20Core_Photo%20Credits_07_U27.pdf`
      },
      {
        unitNumber: 28,
        name: 'WSE NSE Photo Credits, L7, U28, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Core/WSE%20NSE%20Photo%20Credits,%20L7,%20U28,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 7 Assessment Credits',
    files: [
      {
        unitNumber: 25,
        name: 'WSE NSE Photo Credits, L6, U22, AS_HH 08.16.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Assessment/WSE%20NSE%20Photo%20Credits,%20L7,%20U25,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 26,
        name: 'WSE NSE Photo Credits, L7, U26, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Assessment/WSE%20NSE%20Photo%20Credits,%20L7,%20U26,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 27,
        name: 'WSE NSE Photo Credits, L7, U27, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Assessment/WSE%20NSE%20Photo%20Credits,%20L7,%20U27,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 28,
        name: 'WSE NSE Photo Credits, L7, U28, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Assessment/WSE%20NSE%20Photo%20Credits,%20L7,%20U28,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L7, CP_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Assessment/WSE%20NSE%20Photo%20Credits,%20L7,%20CP_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 7 Workbook Credits',
    files: [
      {
        unitNumber: 25,
        name: 'WSE NSE Photo Credits, L7, U25, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%207/DSW/WSE%20NSE%20Photo%20Credits,%20L7,%20U25,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 26,
        name: 'WSE NSE Photo Credits, L7, U26, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%207/DSW/WSE%20NSE%20Photo%20Credits,%20L7,%20U26,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 27,
        name: 'WSE NSE Photo Credits, L7, U27, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%207/DSW/WSE%20NSE%20Photo%20Credits,%20L7,%20U27,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 28,
        name: 'WSE NSE Photo Credits, L7, U28, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%207/DSW/WSE%20NSE%20Photo%20Credits,%20L7,%20U28,%20SM_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 8 Core Credits',
    files: [
      {
        unitNumber: 29,
        name: 'WSE NSE Photo Credits, L8, U29, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%208/Core/WSE%20NSE%20Photo%20Credits,%20L8,%20U29,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 30,
        name: 'WSE NSE Photo Credits, L8, U30, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%208/Core/WSE%20NSE%20Core_PhotoCredits_08_U30.pdf`
      },
      {
        unitNumber: 31,
        name: 'WSE NSE Photo Credits, L8, U31, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%208/Core/WSE%20NSE%20Photo%20Credits,%20L8,%20U31,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 32,
        name: 'WSE NSE Photo Credits, L8, U32, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%208/Core/WSE%20NSE%20Photo%20Credits,%20L8,%20U32,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 8 Assessment Credits',
    files: [
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L8, CP_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%207/Core/WSE%20NSE%20Photo%20Credits,%20L7,%20U25,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 8 Workbook Credits',
    files: [
      {
        unitNumber: 29,
        name: 'WSE NSE Photo Credits, L8, U29, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%208/DSW/WSE%20NSE%20Photo%20Credits,%20L8,%20U29,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 30,
        name: 'WSE NSE Photo Credits, L8, U30, SM_HH 09.06.15_REVnl.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%208/DSW/WSE%20NSE%20Photo%20Credits,%20L8,%20U30,%20SM_HH%2009.06.15_REVnl.pdf`
      },
      {
        unitNumber: 31,
        name: 'WSE NSE Photo Credits, L8, U31, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%208/DSW/WSE%20NSE%20Photo%20Credits,%20L8,%20U31,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 32,
        name: 'WSE NSE Photo Credits, L8, U32, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%208/DSW/WSE%20NSE%20Photo%20Credits,%20L8,%20U32,%20SM_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 9 Core Credits',
    files: [
      {
        unitNumber: 33,
        name: 'WSE NSE Photo Credits, L9, U33, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Core/WSE%20NSE%20Core_PhotoCredits_09_U33.pdf`
      },
      {
        unitNumber: 34,
        name: 'WSE NSE Photo Credits, L9, U34, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Core/WSE%20NSE%20Core_PhotoCredits_09_U34.pdf`
      },
      {
        unitNumber: 35,
        name: 'WSE NSE Photo Credits, L9, U35, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Core/WSE%20NSE%20Core_PhotoCredits_09_U35.pdf`
      },
      {
        unitNumber: 36,
        name: 'WSE NSE Photo Credits, L9, U36, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Core/WSE%20NSE%20Core_Photo%20Credits_09_U36.pdf`
      }
    ]
  },
  {
    name: 'Level 9 Assessment Credits',
    files: [
      {
        unitNumber: 33,
        name: 'WSE NSE Photo Credits, L9, U33, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Assessment/WSE%20NSE%20Photo%20Credits,%20L9,%20U33,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 34,
        name: 'WSE NSE Photo Credits, L9, U34, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Assessment/WSE%20NSE%20Photo%20Credits,%20L9,%20U34,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 35,
        name: 'WSE NSE Photo Credits, L9, U35, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Assessment/WSE%20NSE%20Photo%20Credits,%20L9,%20U35,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 36,
        name: 'WSE NSE Photo Credits, L9, U36, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Assessment/WSE%20NSE%20Photo%20Credits,%20L9,%20U35,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L9, CP_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%209/Assessment/WSE%20NSE%20Photo%20Credits,%20L9,%20CP_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 9 Workbook Credits',
    files: [
      {
        unitNumber: 33,
        name: 'WSE NSE Photo Credits, L9, U33, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%209/DSW/WSE%20NSE%20Photo%20Credits,%20L9,%20U33,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 34,
        name: 'WSE NSE Photo Credits, L9, U34, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%209/DSW/WSE%20NSE%20Photo%20Credits,%20L9,%20U34,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 35,
        name: 'WSE NSE Photo Credits, L9, U35, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%209/DSW/WSE%20NSE%20Photo%20Credits,%20L9,%20U35,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 36,
        name: 'WSE NSE Photo Credits, L9, U36, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%209/DSW/WSE%20NSE%20Photo%20Credits,%20L9,%20U36,%20SM_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 10 Core Credits',
    files: [
      {
        unitNumber: 37,
        name: 'WSE NSE Photo Credits, L10, U37, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Core/WSE%20NSE%20Core_PhotoCredits_10_U37.pdf`
      },
      {
        unitNumber: 38,
        name: 'WSE NSE Photo Credits, L10, U38, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Core/WSE%20NSE%20Core_PhotoCredits_10_U38.pdf`
      },
      {
        unitNumber: 39,
        name: 'WSE NSE Photo Credits, L10, U39, CA_HH 09.08.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Core/WSE%20NSE%20Photo%20Credits,%20L10,%20U39,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 40,
        name: 'WSE NSE Photo Credits, L10, U40, CA_HH 17.22.06.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Core/WSE%20NSE%20Core_PhotoCredits_10_U40.pdf`
      }
    ]
  },
  {
    name: 'Level 10 Assessment Credits',
    files: [
      {
        unitNumber: 37,
        name: 'WSE NSE Photo Credits, L10, U37, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Assessment/WSE%20NSE%20Photo%20Credits,%20L10,%20U37,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 38,
        name: 'WSE NSE Photo Credits, L10, U38, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Assessment/WSE%20NSE%20Photo%20Credits,%20L10,%20U38,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 39,
        name: 'WSE NSE Photo Credits, L10, U39, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Assessment/WSE%20NSE%20Photo%20Credits,%20L10,%20U39,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 40,
        name: 'WSE NSE Photo Credits, L10, U40, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Assessment/WSE%20NSE%20Photo%20Credits,%20L10,%20U40,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L10, CP_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2010/Assessment/WSE%20NSE%20Photo%20Credits,%20L10,%20CP_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 10 Workbook Credits',
    files: [
      {
        unitNumber: 37,
        name: 'WSE NSE Photo Credits, L10, U37, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2010/DSW/WSE%20NSE%20Photo%20Credits,%20L10,%20U37,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 38,
        name: 'WSE NSE Photo Credits, L10, U38, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2010/DSW/WSE%20NSE%20Photo%20Credits,%20L10,%20U38,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 39,
        name: 'WSE NSE Photo Credits, L10, U39, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2010/DSW/WSE%20NSE%20Photo%20Credits,%20L10,%20U39,%20SM_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 40,
        name: 'WSE NSE Photo Credits, L10, U40, SM_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2010/DSW/WSE%20NSE%20Photo%20Credits,%20L10,%20U40,%20SM_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 11 Core Credits',
    files: [
      {
        unitNumber: 41,
        name: 'WSE NSE Photo Credits, L11, U41, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Core/WSE%20NSE%20Photo%20Credits,%20L11,%20U41,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 42,
        name: 'WSE NSE Photo Credits, L11, U42, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Core/WSE%20NSE%20Photo%20Credits,%20L11,%20U42,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 43,
        name: 'WSE NSE Photo Credits, L11, U43, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Core/WSE%20NSE%20Photo%20Credits,%20L11,%20U43,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 44,
        name: 'WSE NSE Photo Credits, L11, U44, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Core/WSE%20NSE%20Photo%20Credits,%20L11,%20U44,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 11 Assessment Credits',
    files: [
      {
        unitNumber: 41,
        name: 'WSE NSE Photo Credits, L11, U41, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Assessment/WSE%20NSE%20Photo%20Credits,%20L11,%20U41,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 42,
        name: 'WSE NSE Photo Credits, L11, U42, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Assessment/WSE%20NSE%20Photo%20Credits,%20L11,%20U42,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 43,
        name: 'WSE NSE Photo Credits, L11, U43, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Assessment/WSE%20NSE%20Photo%20Credits,%20L11,%20U43,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 44,
        name: 'WSE NSE Photo Credits, L11, U44, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Assessment/WSE%20NSE%20Photo%20Credits,%20L11,%20U44,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L11, CP_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2011/Assessment/WSE%20NSE%20Photo%20Credits,%20L11,%20CP_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 11 Workbook Credits',
    files: [
      {
        unitNumber: 41,
        name: 'WSE NSE Photo Credits, L11, U41, SM_HH 09.10.15_REVnl.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2011/DSW/WSE%20NSE%20Photo%20Credits,%20L11,%20U41,%20SM_HH%2009.10.15_REVnl.pdf`
      },
      {
        unitNumber: 42,
        name: 'WSE NSE Photo Credits, L11, U42, SM_HH 09.10.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2011/DSW/WSE%20NSE%20Photo%20Credits,%20L11,%20U42,%20SM_HH%2009.10.15.pdf`
      },
      {
        unitNumber: 43,
        name: 'WSE NSE Photo Credits, L11, U43, SM_HH 09.10.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2011/DSW/WSE%20NSE%20Photo%20Credits,%20L11,%20U43,%20SM_HH%2009.10.15.pdf`
      },
      {
        unitNumber: 44,
        name: 'WSE NSE Photo Credits, L11, U44, SM_HH 09.10.15_REVnl.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2011/DSW/WSE%20NSE%20Photo%20Credits,%20L11,%20U44,%20SM_HH%2009.10.15_REVnl.pdf`
      }
    ]
  },
  {
    name: 'Level 12 Core Credits',
    files: [
      {
        unitNumber: 45,
        name: 'WSE NSE Photo Credits, L12, U45, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Core/WSE%20NSE%20Photo%20Credits,%20L12,%20U45,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 46,
        name: 'WSE NSE Photo Credits, L12, U46, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Core/WSE%20NSE%20Photo%20Credits,%20L12,%20U46,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 47,
        name: 'WSE NSE Photo Credits, L12, U47, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Core/WSE%20NSE%20Photo%20Credits,%20L12,%20U47,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 48,
        name: 'WSE NSE Photo Credits, L12, U48, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Core/WSE%20NSE%20Photo%20Credits,%20L12,%20U48,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 12 Assessment Credits',
    files: [
      {
        unitNumber: 45,
        name: 'WSE NSE Photo Credits, L12, U45, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Assessment/WSE%20NSE%20Photo%20Credits,%20L12,%20U45,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 46,
        name: 'WSE NSE Photo Credits, L12, U46, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Assessment/WSE%20NSE%20Photo%20Credits,%20L12,%20U46,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 47,
        name: 'WSE NSE Photo Credits, L12, U47, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Assessment/WSE%20NSE%20Photo%20Credits,%20L12,%20U47,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 48,
        name: 'WSE NSE Photo Credits, L12, U48, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2012/Assessment/WSE%20NSE%20Photo%20Credits,%20L12,%20U48,%20AS_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 12 Workbook Credits',
    files: [
      {
        unitNumber: 45,
        name: 'WSE NSE Photo Credits, L12, U45, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2012/DSW/WSE%20NSE%20Photo%20Credits,%20L12,%20U45,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 46,
        name: 'WSE NSE Photo Credits, L12, U46, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2012/DSW/WSE%20NSE%20Photo%20Credits,%20L12,%20U46,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 47,
        name: 'WSE NSE Photo Credits, L12, U47, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2012/DSW/WSE%20NSE%20Photo%20Credits,%20L12,%20U47,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 48,
        name: 'WSE NSE Photo Credits, L12, U48, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2012/DSW/WSE%20NSE%20Photo%20Credits,%20L12,%20U48,%20SM_HH%2009.22.15.pdf`
      }
    ]
  },
  {
    name: 'Level 13 Core Credits',
    files: [
      {
        unitNumber: 49,
        name: 'WSE NSE Photo Credits, L13, U49, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Core/WSE%20NSE%20Photo%20Credits,%20L13,%20U49,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 50,
        name: 'WSE NSE Photo Credits, L13, U50, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Core/WSE%20NSE%20Photo%20Credits,%20L13,%20U50,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 51,
        name: 'WSE NSE Photo Credits, L13, U51, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Core/WSE%20NSE%20Photo%20Credits,%20L13,%20U51,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 52,
        name: 'WSE NSE Photo Credits, L13, U52, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Core/WSE%20NSE%20Photo%20Credits,%20L13,%20U52,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 13 Assessment Credits',
    files: [
      {
        unitNumber: 49,
        name: 'WSE NSE Photo Credits, L13, U49, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Assessment/WSE%20NSE%20Photo%20Credits,%20L13,%20U49,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 50,
        name: 'WSE NSE Photo Credits, L13, U50, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Assessment/WSE%20NSE%20Photo%20Credits,%20L13,%20U51,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 51,
        name: 'WSE NSE Photo Credits, L13, U51, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2013/Assessment/WSE%20NSE%20Photo%20Credits,%20L13,%20U51,%20AS_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 13 Workbook Credits',
    files: [
      {
        unitNumber: 49,
        name: 'WSE NSE Photo Credits, L13, U49, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2013/DSW/WSE%20NSE%20Photo%20Credits,%20L13,%20U49,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 50,
        name: 'WSE NSE Photo Credits, L13, U50, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2013/DSW/WSE%20NSE%20Photo%20Credits,%20L13,%20U50,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 51,
        name: 'WSE NSE Photo Credits, L13, U51, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2013/DSW/WSE%20NSE%20Photo%20Credits,%20L13,%20U51,%20SM_HH%2009.22.15.pdf`
      },
      {
        unitNumber: 52,
        name: 'WSE NSE Photo Credits, L13, U52, SM_HH 09.22.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2013/DSW/WSE%20NSE%20Photo%20Credits,%20L13,%20U52,%20SM_HH%2009.22.15.pdf`
      }
    ]
  },
  {
    name: 'Level 14 Core Credits',
    files: [
      {
        unitNumber: 53,
        name: 'WSE NSE Photo Credits, L14, U53, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Core/WSE%20NSE%20Photo%20Credits,%20L14,%20U53,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 54,
        name: 'WSE NSE Photo Credits, L14, U54, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Core/WSE%20NSE%20Photo%20Credits,%20L14,%20U54,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 55,
        name: 'WSE NSE Photo Credits, L14, U55, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Core/WSE%20NSE%20Photo%20Credits,%20L14,%20U55,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 56,
        name: 'WSE NSE Photo Credits, L14, U56, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Core/WSE%20NSE%20Photo%20Credits,%20L14,%20U56,%20CA_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 14 Assessment Credits',
    files: [
      {
        unitNumber: 53,
        name: 'WSE NSE Photo Credits, L14, U53, AS_HH 09.06.15 (1).pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Assessment/WSE%20NSE%20Photo%20Credits,%20L14,%20U53,%20AS_HH%2009.06.15%20(1).pdf`
      },
      {
        unitNumber: 56,
        name: 'WSE NSE Photo Credits, L14, U56, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2014/Assessment/WSE%20NSE%20Photo%20Credits,%20L14,%20U56,%20AS_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 14 Workbook Credits',
    files: [
      {
        unitNumber: 53,
        name: 'WSE NSE Photo Credits, L14, U53, SM_HH 09.25.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2014/DSW/WSE%20NSE%20Photo%20Credits,%20L14,%20U53,%20SM_HH%2009.25.15.pdf`
      },
      {
        unitNumber: 54,
        name: 'WSE NSE Photo Credits, L14, U54, SM_HH 09.25.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2014/DSW/WSE%20NSE%20Photo%20Credits,%20L14,%20U54,%20SM_HH%2009.25.15.pdf`
      },
      {
        unitNumber: 55,
        name: 'WSE NSE Photo Credits, L14, U55, SM_HH 09.25.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2014/DSW/WSE%20NSE%20Photo%20Credits,%20L14,%20U55,%20SM_HH%2009.25.15.pdf`
      },
      {
        unitNumber: 56,
        name: 'WSE NSE Photo Credits, L14, U56, SM_HH 09.25.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2014/DSW/WSE%20NSE%20Photo%20Credits,%20L14,%20U56,%20SM_HH%2009.25.15.pdf`
      }
    ]
  },
  {
    name: 'Level 15 Core Credits',
    files: [
      {
        unitNumber: 57,
        name: 'WSE NSE Photo Credits, L15, U57, CA_HH 09.22.15_REVnl.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Core/WSE%20NSE%20Photo%20Credits,%20L15,%20U57,%20CA_HH%2009.22.15_REVnl.pdf`
      },
      {
        unitNumber: 58,
        name: 'WSE NSE Photo Credits, L15, U58, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Core/WSE%20NSE%20Photo%20Credits,%20L15,%20U58,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 59,
        name: 'WSE NSE Photo Credits, L15, U59, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Core/WSE%20NSE%20Photo%20Credits,%20L15,%20U59,%20CA_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 60,
        name: 'WSE NSE Photo Credits, L15, U60, CA_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Core/WSE%20NSE%20Photo%20Credits,%20L15,%20U60,%20CA_HH%2009.08.15.pdf`
      }
    ]
  },
  {
    name: 'Level 15 Assessment Credits',
    files: [
      {
        unitNumber: 57,
        name: 'WSE NSE Photo Credits, L15, U57, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Assessment/WSE%20NSE%20Photo%20Credits,%20L15,%20U57,%20AS_HH%2009.06.15.pdf`
      },
      {
        unitNumber: 59,
        name: 'WSE NSE Photo Credits, L15, U59, AS_HH 09.06.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2015/Assessment/WSE%20NSE%20Photo%20Credits,%20L15,%20U59,%20AS_HH%2009.06.15.pdf`
      }
    ]
  },
  {
    name: 'Level 15 Workbook Credits',
    files: [
      {
        unitNumber: 57,
        name: 'WSE NSE Photo Credits, L15, U57, SM_HH 09.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2015/DSW/WSE%20NSE%20Photo%20Credits,%20L15,%20U57,%20SM_HH%2009.29.15.pdf`
      },
      {
        unitNumber: 58,
        name: 'WSE NSE Photo Credits, L15, U58, SM_HH 09.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2015/DSW/WSE%20NSE%20Photo%20Credits,%20L15,%20U58,%20SM_HH%2009.29.15.pdf`
      },
      {
        unitNumber: 59,
        name: 'WSE NSE Photo Credits, L15, U59, SM_HH 09.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2015/DSW/WSE%20NSE%20Photo%20Credits,%20L15,%20U59,%20SM_HH%2009.29.15.pdf`
      },
      {
        unitNumber: 60,
        name: 'WSE NSE Photo Credits, L15, U60, SM_HH 09.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2015/DSW/WSE%20NSE%20Photo%20Credits,%20L15,%20U60,%20SM_HH%2009.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 16 Core Credits',
    files: [
      {
        unitNumber: 61,
        name: 'WSE NSE Photo Credits, L16, U61, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2016/Core/WSE%20NSE%20Photo%20Credits,%20L16,%20U61,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 62,
        name: 'WSE NSE Photo Credits, L16, U62, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2016/Core/WSE%20NSE%20Photo%20Credits,%20L16,%20U62,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 63,
        name: 'WSE NSE Photo Credits, L16, U63, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2016/Core/WSE%20NSE%20Photo%20Credits,%20L16,%20U63,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 64,
        name: 'WSE NSE Photo Credits, L16, U64, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2016/Core/WSE%20NSE%20Photo%20Credits,%20L16,%20U64,%20CA_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 16 Workbook Credits',
    files: [
      {
        unitNumber: 61,
        name: 'WSE NSE Photo Credits, L16, U61, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2016/DSW/WSE%20NSE%20Photo%20Credits,%20L16,%20U61,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 62,
        name: 'WSE NSE Photo Credits, L16, U62, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2016/DSW/WSE%20NSE%20Photo%20Credits,%20L16,%20U62,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 63,
        name: 'WSE NSE Photo Credits, L16, U63, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2016/DSW/WSE%20NSE%20Photo%20Credits,%20L16,%20U63,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 64,
        name: 'WSE NSE Photo Credits, L16, U64, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2016/DSW/WSE%20NSE%20Photo%20Credits,%20L16,%20U64,%20SM_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 17 Core Credits',
    files: [
      {
        unitNumber: 65,
        name: 'WSE NSE Photo Credits, L17, U65, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2017/Core/WSE%20NSE%20Photo%20Credits,%20L17,%20U65,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 66,
        name: 'WSE NSE Photo Credits, L17, U66, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2017/Core/WSE%20NSE%20Photo%20Credits,%20L17,%20U66,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 67,
        name: 'WSE NSE Photo Credits, L17, U67, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2017/Assessment/WSE%20NSE%20Photo%20Credits,%20L17,%20U67,%20CA_HH%2010.29.pdf`
      },
      {
        unitNumber: 68,
        name: 'WSE NSE Photo Credits, L17, U68, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2017/Core/WSE%20NSE%20Photo%20Credits,%20L17,%20U68,%20CA_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 17 Assessment Credits',
    files: [
      {
        unitNumber: 'CP',
        name: 'WSE NSE Photo Credits, L17, CP_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2017/Assessment/WSE%20NSE%20Photo%20Credits,%20L17,%20CP_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 17 Workbook Credits',
    files: [
      {
        unitNumber: 65,
        name: 'WSE NSE Photo Credits, L17, U65, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2017/DSW/WSE%20NSE%20Photo%20Credits,%20L17,%20U65,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 66,
        name: 'WSE NSE Photo Credits, L17, U66, SM_HH 10.29.15_nl 11-3-15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2017/DSW/WSE%20NSE%20Photo%20Credits,%20L17,%20U66,%20SM_HH%2010.29.15_nl%2011-3-15.pdf`
      },
      {
        unitNumber: 67,
        name: 'WSE NSE Photo Credits, L17, U67, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2017/DSW/WSE%20NSE%20Photo%20Credits,%20L17,%20U67,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 68,
        name: 'WSE NSE Photo Credits, L17, U68, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2017/DSW/WSE%20NSE%20Photo%20Credits,%20L17,%20U68,%20SM_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 18 Core Credits',
    files: [
      {
        unitNumber: 69,
        name: 'WSE NSE Photo Credits, L18, U69, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2018/Core/WSE%20NSE%20Photo%20Credits,%20L18,%20U69,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 70,
        name: 'WSE NSE Photo Credits, L18, U70, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2018/Core/WSE%20NSE%20Photo%20Credits,%20L18,%20U70,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 71,
        name: 'WSE NSE Photo Credits, L18, U71, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2018/Core/WSE%20NSE%20Photo%20Credits,%20L18,%20U71,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 72,
        name: 'WSE NSE Photo Credits, L18, U72, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2018/Core/WSE%20NSE%20Photo%20Credits,%20L18,%20U72,%20CA_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 18 Workbook Credits',
    files: [
      {
        unitNumber: 69,
        name: 'WSE NSE Photo Credits, L18, U69, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2018/DSW/WSE%20NSE%20Photo%20Credits,%20L18,%20U69,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 70,
        name: 'WSE NSE Photo Credits, L18, U70, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2018/DSW/WSE%20NSE%20Photo%20Credits,%20L18,%20U70,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 71,
        name: 'WSE NSE Photo Credits, L18, U71, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2018/DSW/WSE%20NSE%20Photo%20Credits,%20L18,%20U71,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 72,
        name: 'WSE NSE Photo Credits, L18, U72, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2018/DSW/WSE%20NSE%20Photo%20Credits,%20L18,%20U72,%20SM_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 19 Core Credits',
    files: [
      {
        unitNumber: 73,
        name: 'WSE NSE Photo Credits, L19, U73, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2019/Core/WSE%20NSE%20Photo%20Credits,%20L19,%20U73,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 74,
        name: 'WSE NSE Photo Credits, L19, U74, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2019/Core/WSE%20NSE%20Photo%20Credits,%20L19,%20U74,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 75,
        name: 'WSE NSE Photo Credits, L19, U75, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2019/Core/WSE%20NSE%20Photo%20Credits,%20L19,%20U75,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 76,
        name: 'WSE NSE Photo Credits, L19, U76, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2019/Core/WSE%20NSE%20Photo%20Credits,%20L19,%20U76,%20CA_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 19 Workbook Credits',
    files: [
      {
        unitNumber: 73,
        name: 'WSE NSE Photo Credits, L19, U73, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2019/DSW/WSE%20NSE%20Photo%20Credits,%20L19,%20U73,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 74,
        name: 'WSE NSE Photo Credits, L19, U74, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2019/DSW/WSE%20NSE%20Photo%20Credits,%20L19,%20U74,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 75,
        name: 'WSE NSE Photo Credits, L19, U75, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2019/DSW/WSE%20NSE%20Photo%20Credits,%20L19,%20U75,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 76,
        name: 'WSE NSE Photo Credits, L19, U76, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2019/DSW/WSE%20NSE%20Photo%20Credits,%20L19,%20U76,%20SM_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 20 Core Credits',
    files: [
      {
        unitNumber: 77,
        name: 'WSE NSE Photo Credits, L20, U77, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2020/Core/WSE%20NSE%20Photo%20Credits,%20L20,%20U77,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 78,
        name: 'WSE NSE Photo Credits, L20, U78, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2020/Core/WSE%20NSE%20Photo%20Credits,%20L20,%20U78,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 79,
        name: 'WSE NSE Photo Credits, L20, U79, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2020/Core/WSE%20NSE%20Photo%20Credits,%20L20,%20U79,%20CA_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 80,
        name: 'WSE NSE Photo Credits, L20, U80, CA_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_ASSET_URL}credits/Level%2020/Core/WSE%20NSE%20Photo%20Credits,%20L20,%20U80,%20CA_HH%2010.29.15.pdf`
      }
    ]
  },
  {
    name: 'Level 20 Workbook Credits',
    files: [
      {
        unitNumber: 77,
        name: 'WSE NSE Photo Credits, L20, U77, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2020/DSW/WSE%20NSE%20Photo%20Credits,%20L20,%20U77,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 78,
        name: 'WSE NSE Photo Credits, L20, U78, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2020/DSW/WSE%20NSE%20Photo%20Credits,%20L20,%20U78,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 79,
        name: 'WSE NSE Photo Credits, L20, U79, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2020/DSW/WSE%20NSE%20Photo%20Credits,%20L20,%20U79,%20SM_HH%2010.29.15.pdf`
      },
      {
        unitNumber: 80,
        name: 'WSE NSE Photo Credits, L20, U80, SM_HH 10.29.15.pdf',
        url: `${process.env.REACT_APP_DSW_ASSET_URL}credits/Level%2020/DSW/WSE%20NSE%20Photo%20Credits,%20L20,%20U80,%20SM_HH%2010.29.15.pdf`
      }
    ]
  }
];

export default credits;
