import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Pagination.css';

/**
 * Pagination component
 * @param {number} totalData
 * @param {number} perPage
 * @param {Function} onPageClickHandler
 */
const Pagination = ({ totalData, perPage, onPageClickHandler }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = Math.ceil(totalData / perPage);
  const pageList = Array.from({ length: lastPage }, (a, b) => { return b + 1; });

  useEffect(() => {
    onPageClickHandler(currentPage);
  }, [currentPage]);

  return (
    <ul className="dsw-pagination">
      <li className={currentPage === 1 ? 'disabled' : ''}>
        <a onClick={() => { return setCurrentPage(1); }}>First</a>
      </li>
      <li className={currentPage === 1 ? 'disabled' : ''}>
        <a onClick={() => { return setCurrentPage(currentPage - 1); }}>Previous</a>
      </li>
      {pageList && pageList.map((item) => {
        return (
          <li key={item} className={currentPage === item ? 'active' : ''}>
            <a onClick={() => { return setCurrentPage(item); }}>{item}</a>
          </li>
        );
      })}
      <li className={currentPage === lastPage ? 'disabled' : ''}>
        <a onClick={() => { return setCurrentPage(currentPage + 1); }}>Next</a>
      </li>
      <li className={currentPage === lastPage ? 'disabled' : ''}>
        <a onClick={() => { return setCurrentPage(lastPage); }}>Last</a>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  totalData: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onPageClickHandler: PropTypes.func.isRequired
};

export default Pagination;
