import React from 'react';

/**
 * TermsOfUse component
 */
const TermsOfUse = () => {
  return (
    <>
      <p className="wse-typo-body policy-updated-date">(Updated September 27, 2021)</p>

      <p className="wse-typo-body">
        This site is owned by WSE Hong Kong Limited, WSE Hong Kong Limited,
        a company established under the laws of Hong Kong (registered number 2587136,
        registered address at Rm. 2608, W50, 50 Wong Chuk Hang Road, Hong Kong SAR).
        References to “Wall Street English”, “we”, “us”, “our” or similar refer to
        WSE Hong Kong Limited or whichever Wall Street English group company is
        operating this site. Our “affiliated entities” mean companies
        which are under the control of WSE Hong Kong Limited.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Terms and Conditions </strong>
      </p>

      <p className="wse-typo-body">
        If you are under 18 years of age, please be sure to read these Terms
        and Conditions with your parent or guardian and ask questions about
        things you do not understand.
      </p>

      <p className="wse-typo-body">
        These Terms and Conditions apply to your use of this site, its features
        and contents. Please read these Terms and Conditions carefully before
        using and/or registering to this site because by accessing or using
        this site or by linking to it, you signify that you have read, understood
        and agreed to be bound by these Terms and Conditions. These Terms and
        Conditions are a legally binding agreement between you and us.
      </p>

      <p className="wse-typo-body">
        You are responsible for bringing these terms to the attention of anyone
        who may read material on this site as a result of your access to it.
      </p>

      <p className="wse-typo-body">
        We reserve the right, at our sole discretion, to change, add or delete
        portions of the Terms and Conditions at any time. If we do this, we will
        post the changes to these Terms and Conditions on this Terms and
        Conditions page and will indicate at the top of it the date these terms
        were last revised. We may send registered users and subscribers an
        email notifying you of the change. Your continued use of this site after
        any such change whether or not you received an email from us notifying you
        of any changes, constitutes your acceptance of the new Terms and Conditions.
        If you do not agree to abide by these or any future Terms and Conditions,
        please do not use or access (or continue to use or access) this site.
      </p>

      <p className="wse-typo-body">
        We reserve the right, at our sole discretion, to make any changes to this
        site and levels of service offered and to terminate access at any time.
      </p>

      <p className="wse-typo-body">
        You must not use data mining, robots, scraping or similar data gathering
        or extraction methods on any part of this site without our express prior written consent.
      </p>

      <p className="wse-typo-body">
        Please note that the usage and privacy policies of various Wall Street English
        products and services within this site or linked to this site may be subject to
        more specific terms. If there is a conflict between those more specific terms
        and these Terms and Conditions, then the more specific terms shall apply.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Proprietary Rights in Content; Limited Licence</strong>
      </p>

      <p className="wse-typo-body">
        All material on or available through this site is (unless otherwise stated) copyright
        of WSE Hong Kong Limited and its licensors. All rights are reserved.
      </p>

      <p className="wse-typo-body">
        Users are permitted to view, print and download the material for using it within the
        Wall Street English business only.
      </p>

      <p className="wse-typo-body">
        Users are not entitled to modify the content of this site or reproduce, republish,
        distribute, transmit, link, frame or deep-link it on any other site without the
        express permission of Wall Street English. Users may not distribute, transmit,
        reproduce or publish by electronic or any other means any part of the data without
        the prior written permission of Wall Street English. Users may not create a database
        in electronic or structured manual form by systematically downloading and storing all
        or any of the content. All other rights reserved.
      </p>

      <p className="wse-typo-body">
        All trademarks used on this site are the property of their respective owners. The use
        of any trademark on our site does not imply any affiliation with or endorsement of our
        site by such owners.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Registration Data and Account Security </strong>
      </p>

      <p className="wse-typo-body">
        When you register for any services offered on this site requiring registration, you are
        asked to provide specific information as set out in the registration form
        (“Registration Details”). You must provide accurate Registration Details and
        update your Registration Details as necessary so that they maintain their accuracy.
        You must keep your password and identification details secure and you are fully responsible
        for all use of your account and for any actions that take place using your account.
      </p>

      <p className="wse-typo-body">
        You must not allow others to use your Registration Details or account, and you are solely
        responsible for maintaining the confidentiality and security of your account. You agree
        to notify Wall Street English immediately of any unauthorised use of your Registration
        Details or account. Wall Street English will not be liable for any losses arising out of
        the unauthorised use of your Registration Details and/or account and you agree to indemnify
        and hold harmless Wall Street English or any associated companies, agents, affiliates and/or
        licensors, as applicable, for unauthorised uses of your account.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Privacy </strong>
      </p>

      <p className="wse-typo-body">
        Registration Details or other information you provide to us as part of our registration
        process will be treated in accordance with our
        <strong>
          <u> Privacy Policy </u>
        </strong>
        . By using this site, you are consenting to have your personal data transferred to and
        processed by us in line with our Privacy Policy which forms part of these
        Terms and Conditions.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>System Requirements</strong>
      </p>

      <p className="wse-typo-body">The minimum system requirements to allow full access to this site are as follows:</p>

      <p className="wse-typo-body">Browser: Internet Explorer 8+, Chrome 2+, Firefox 3.5+, and Safari</p>

      <p className="wse-typo-body">Resolution: 1024x768 pixels</p>

      <p className="wse-typo-body">
        Internet access at 5-10Mbps is generally advisable to have a good user experience.
      </p>

      <p className="wse-typo-body">
        Wall Street English assumes no liability in the event that your hardware or software
        does not comply with the minimum requirements specified above. Wall Street English assumes
        no responsibility for the equipment and/or service providers
        (ISP, telecom operator, etc) that you select to access to this site.
      </p>

      <p className="wse-typo-body">
        You can access your account from more than one device but we recommend that you access
        your account from one device at a time only. If you have access to your account
        simultaneously from more than one device the system may not recognise or save properly
        certain content provided through this site and we assume no responsibility.
      </p>

      <p className="wse-typo-body">
        You can download certain content on up to four devices at a time but if you work
        offline, remember to save any changes or work in progress and synchronise it online
        before you finish your session so that you have the most updated information
        when accessing your account from a different device.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>User Conduct </strong>
      </p>

      <p className="wse-typo-body">
        The site and all its features, whether or not requiring password registration or payment,
        is available for your professional use within the Wall Street English business only.
        You represent and warrant and agree that no materials of any kind submitted to this site,
        including if you registered with us through your account, or otherwise posted,
        transmitted or shared by you will violate or infringe upon the rights of any third party,
        including copyright, trademark, privacy, publicity or other personal or proprietary rights
        or contain libellous, defamatory or otherwise harmful material. You may not use this
        site or any of its features for any illegal or unauthorised purpose.
        You agree to abide by all applicable local, state, national and international laws and
        regulations and are solely responsible for all your acts or omissions
        and for all those that occur under your account.
      </p>

      <p className="wse-typo-body">
        By submitting any material to us you agree to abide by the following rules. Wall Street
        English reserves the right but does not have an obligation to monitor materials or any
        information which is posted on any part of this site, any bulletin boards, discussion
        forums or any tools available on this site allowing you to post comments, material, views,
        reviews or comment on material posted by you, us or others or to interact with others
        or materials in any way (“the Interactive Areas”) and on any part of this site requiring
        registration or subscription allowing you to do any of the above.
      </p>

      <p className="wse-typo-body">
        We also have the right, but no obligation, at any time to delete,
        remove, or suspend the whole or any part of the materials on this site or any content
        posted on them including in any Interactive Areas and areas requiring registration or
        subscription without notice and without incurring any liability.
        <strong>
          {' '}
          No part of our site is meant for you to post third-party
          copyrighted content
        </strong>
        . That includes the Interactive Areas including those requiring registration and/or
        subscription. “Copyrighted content means third-party copyright content such as
        questionnaires, surveys, songs, film clips, an episode of a TV programme,
        parts of books, news articles, illustrations, photos or music videos.
      </p>

      <p className="wse-typo-body">If you find objectionable or offensive material please let us know as soon as possible.</p>

      <p className="wse-typo-body">
        The views, statements and opinions expressed in any of the Interactive Areas in any
        form are those of the individual contributors or advertisers and not the statements
        and opinions of Wall Street English or its affiliated entities.
      </p>

      <p className="wse-typo-body">
        By posting your comments or materials on the Interactive Areas, you grant us and our
        affiliated entities a non-exclusive, perpetual, irrevocable, royalty-free, world-wide
        licence to use, reproduce, modify, adapt, translate, publish, make available, distribute,
        include in other works, sub-license and display any content you submit to us in any format
        now known or later developed. By submitting material you acknowledge that you waive any
        moral rights you may have in it.
        <strong>
          {' '}
          If you do not want to grant us these rights, please do not submit your comments
          or materials to us.
        </strong>
      </p>

      <p className="wse-typo-body">
        You may use the Interactive Areas only to post content that is proper and
        appropriate to such areas and you must not do any of the following:
      </p>

      <ol>
        <li>
          advertise, promote or offer to sell any goods or services even if you are acting
          for a charity or non-profit organisation;
        </li>
        <li>
          post, transmit unsolicited promotional material “junk” mail or “spam”, pyramid schemes;
        </li>
        <li>
          harvest or collect email addresses or other contact information or use automated
          scripts to collect information from or otherwise interact with this site;
        </li>
        <li>reveal any confidential or sensitive information;</li>
        <li>
          reveal any personal information on other persons (for example, email address,
          telephone number or postal address, credit card numbers, social security numbers);
        </li>
        <li>
          post or link to any content that we deem unlawful, defamatory, obscene, threatening,
          offensive, fraudulent, harmful or otherwise objectionable (this includes but is not
          limited to text, graphics, video, programs or audio);
        </li>
        <li>
          post content which you do not have permission to use, in particular, material that
          contains the intellectual property rights of third parties;
        </li>
        <li>
          upload, post share or otherwise make available any material that contains software
          viruses, computer codes, worms, trojans or any other components with harmful or
          contaminating effects to any user or equipment;
        </li>
        <li>imply that any statements you make are endorsed by us;</li>
        <li>
          delete any author attributions, legal notices or proprietary designations or labels
          in any content that is posted;
        </li>
        <li>falsify the origin or source of content that is posted;</li>
        <li>contribute content with the intention of committing or promoting an illegal act;</li>
        <li>use inappropriate (e.g. vulgar or offensive) language or user names;</li>
        <li>impersonate any living person or purport to represent any organisation;</li>
        <li>
          use or attempt to use another’s account, service or system without authorisation,
          register for more than one account or register on behalf of another individual,
          group or entity;
        </li>
        <li>
          reformat or frame any portion of the web pages that are part of this site,
          deep link into it without permission.
        </li>
      </ol>

      <p className="wse-typo-body">
        Please bear in mind that we will identify you with content you submitted and may
        release that information to a court or other official if ordered to do so.
      </p>

      <p className="wse-typo-body">
        We may refuse to grant you a username that impersonates someone else, is or may be
        protected by trademark or other intellectual property rights, is vulgar, offensive
        or otherwise inappropriate, as determined by us in our sole discretion.
      </p>

      <p className="wse-typo-body">
        We do not undertake to keep or make available any material that you or anyone else
        has contributed to this site for any length of time or at all. We may delete,
        archive, make unavailable, modify or comment on any material, and close
        or suspend any discussion topic at any time without notice.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>User Disputes </strong>
      </p>

      <p className="wse-typo-body">
        You are solely responsible for your interactions with other users of this site.
        We reserve the right, but have no obligation,
        to monitor disputes between you and other users.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Disclaimers </strong>
      </p>

      <p className="wse-typo-body">
        Although we try to ensure that the information we provide on this site is correct,
        it is provided on an &apos;as is&apos; basis, without warranties of any kind. No warranty,
        express or implied, is given that any of the content of this site is
        accurate, complete or up to date. Wall Street English does not accept any liability
        for any error or omission of any content on this site, including content provided
        by us or any affiliated entities or by any users or other third parties.
      </p>

      <p className="wse-typo-body">
        Any and all representations and warranties whether express or implied, including
        implied warranties of merchantability, fitness for a particular purpose or non-infringement,
        whether implied by statute, common law, custom, trade usage, course of dealing or otherwise
        are excluded to the fullest extent permitted by law. We do not exclude any liability
        in respect of death or personal injury caused by our negligence, liability for our fraud,
        or any other liability that we cannot exclude by law.
      </p>

      <p className="wse-typo-body">We cannot guarantee and do not promise any specific results from use of this site.</p>

      <p className="wse-typo-body">
        <strong>
          The use of this site and any content on any part of it whether requiring registration
          and/or subscription or not is at your own risk. Wall Street English will not be liable
          to any users of this site (whether registered users, subscribers or not) and any third
          parties for any claims or losses of any nature including, but not limited to, loss of
          profits, direct, indirect, special or consequential damages arising from a user’s or third
          party&apos;s use or inability to use this site or any part of it, including any loss of
          data or lost profits or wasted time of management or employees even if Wall Street English
          is aware of or has been advised of the possibility of such damages. Notwithstanding
          anything to the contrary contained herein, Wall Street English’s liability to you for
          any cause whatsoever, and regardless of the form of the action, will at all times
          be limited to the amount paid, if any, by you to us.
        </strong>
      </p>

      <p className="wse-typo-body">
        Wall Street English accepts no responsibility for the content on any third-party app,
        website, survey, platform or other digital space to which a hypertext link from this
        site exists or, after you have followed such hypertext link, for any use of any data
        (including your personal data) by the third party operating such app, website, survey,
        platform or other digital space. The links are provided &apos;as is&apos; with no warranty,
        express or implied, in relation to the information they link to or provide.
      </p>

      <p className="wse-typo-body">
        All providers of a link into this site should note that Wall Street English does not
        necessarily read, check or edit the content from such links and Wall Street English accepts
        such material linked to its site purely on the basis of an understanding that Wall Street
        English is a distributor (not a publisher) of such material and by linking to this site,
        the provider of the link gives an implied warranty that he/she/it will indemnify Wall Street
        English in the event of any action arising as a result of such material appearing on or via
        this site. All persons accessing this site are also put on notice of such fact.
      </p>

      <p className="wse-typo-body">
        While Wall Street English makes all reasonable attempts to exclude viruses or other code
        with contaminating or disruptive properties from this site, it cannot ensure such exclusion
        and no liability is accepted for viruses and any computer code/software. You are recommended
        to take all appropriate safeguards before downloading information from this site.
      </p>

      <p className="wse-typo-body">
        Information transmitted via this site will pass over public telecommunications networks.
        Wall Street English makes no representation or warranty that the operation of this site
        will be uninterrupted or error free and disclaims all liability in this respect. The site
        may be temporarily unavailable from time to time for maintenance or other reasons.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Indemnity </strong>
      </p>

      <p className="wse-typo-body">
        You agree to hold harmless, indemnify, and at our request defend, Wall Street
        English, its parents, subsidiaries and other affiliated entities and their
        respective owners, officers, managers, agents and employees from and against
        any third party claim arising from or in any way related to your material, your
        use of, conduct on this site, including any liability or expense arising from all
        claims, liabilities, losses, damages (actual and consequential), suits,
        judgments, litigation costs, expenses and lawyers’ fees, for every kind and nature.
        In such a case, we will provide you with written notice of such claim,
        suit or action to the address you registered with us.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Termination </strong>
      </p>

      <p className="wse-typo-body">
        Wall Street English may immediately suspend or terminate your access to
        this site without notice if any breach of these
        Terms and Conditions of use by you is brought to our attention.
      </p>

      <p className="wse-typo-body">
        If you breach these Terms and Conditions and we take no action, we will
        not be deemed to have waived any of our rights
        and may still use any rights and remedies that were open to us when you broke them.
      </p>

      <p className="wse-typo-body">
        Your registration may be terminated and your profile and any content or information
        that you have posted on this site may be deleted at any time. We may prohibit you
        from using or accessing this site for any reason, at any time in
        our sole discretion, with or without notice. These Terms and Conditions shall apply
        to any use of this site, also after your registration was terminated.
      </p>

      <p className="wse-typo-body">
        We reserve the right to deactivate user accounts and user names that have been
        inactive for more than 180 consecutive calendar days.
      </p>

      <p className="wse-typo-body">&nbsp;</p>

      <p className="wse-typo-body">
        <strong>Governing Law; Venue and Jurisdiction </strong>
      </p>

      <p className="wse-typo-body">
        By visiting or using the Site you agree that these Terms and Conditions are governed by and
        shall be interpreted in accordance with English law. All disputes arising out of or
        in connection with these Terms and with your use of the site shall be finally settled
        under the Rules of Arbitration of the International Chamber of Commerce by one arbitrator
        appointed in accordance with the said Rules. The arbitration shall take placein London
        (United Kingdom)in English language. The proceedings and the award shall be confidential.
        Without prejudice to the foregoing, we shall have the right to apply at any time for
        injunctive or other interlocutory or emergency relief to any court having jurisdiction
        to grant interim relief.
      </p>

      <p className="wse-typo-body">
        Wall Street English makes efforts to ensure that its publications, advertising and
        offers on this site fully meet the requirements of English law, Hong Kong law and
        foreign laws of any country where it is intended for such content to be viewed. In
        the event that a foreign country’s law may not be fully complied with, then
        Wall Street English shall be deemed not to be directing
        such content to that particular country.
      </p>

      <p className="wse-typo-body">
        These Terms and Conditions constitute the entire agreement
        between you and Wall Street English. Wall Street English shall
        be entitled to enforce this agreement on its own behalf
        and on behalf of and for the benefit of its affiliated entities.
      </p>

      <p className="wse-typo-body">
        Apart from affiliated entities who shall be entitled
        to enforce the terms of this agreement in the same way as Wall Street English,
        nothing in this agreement shall confer
        on any third party any right or benefit under the provisions
        of the Contracts (Rights of Third Parties) Act 1999.
      </p>

      <p className="wse-typo-body">
        If you have any request or comment related to these Terms and Conditions,
        please send an email to
        <a href="mailto:legal@wallstreetenglish.com">
          {' '}
          legal@wallstreetenglish.com
        </a>
      </p>
    </>
  );
};

export default TermsOfUse;
