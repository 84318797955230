export const MY_LIBRARY = 'My Library';
export const Home = 'Home';
export const LOGOUT = 'Log out';
export const TILE_VIEW_CAROUCEL_CONFIG = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 786 },
    items: 3,
    slidesToSlide: 3
  },
  largeMobile: {
    breakpoint: { max: 786, min: 484 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 484, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};
export const PRIVACY_POLICY_CHINA = '用户协议与隐私政策';
export const NOTIFICATION_TYPE = {
  ATTENTION: 'attention',
  CORRECT: 'correct',
  INFORMATION: 'information',
  INCORRECT: 'incorrect'
};
export const SOMETHING_WENT_WRONG = 'Something went wrong! Please try again later.';
export const ISBN_NUMBER = {
  CC_STUDENT_MANUAL_LEVEL1: '978-988-70247-0-5',
  CC_STUDENT_MANUAL_LEVEL2: '978-988-70247-1-2',
  CC_STUDENT_MANUAL_LEVEL3: '978-988-70247-2-9',
  CC_STUDENT_MANUAL_LEVEL4: '978-988-70247-3-6',
  CC_STUDENT_MANUAL_LEVEL5: '978-988-70247-4-3',
  CC_STUDENT_MANUAL_LEVEL6: '978-988-70247-5-0',
  CC_STUDENT_MANUAL_LEVEL7: '978-988-70247-6-7',
  CC_STUDENT_MANUAL_LEVEL8: '978-988-70247-7-4',
  CC_STUDENT_MANUAL_LEVEL9: '978-988-70247-8-1',
  CC_STUDENT_MANUAL_LEVEL10: '978-988-70247-9-8',
  CC_STUDENT_MANUAL_LEVEL11: '978-988-70343-0-8',
  CC_STUDENT_MANUAL_LEVEL12: '978-988-70343-4-6',
  CC_STUDENT_MANUAL_LEVEL13: '978-988-70343-8-4',
  CC_STUDENT_MANUAL_LEVEL14: '978-988-70343-1-5',
  CC_STUDENT_MANUAL_LEVEL15: '978-988-70343-5-3',
  CC_STUDENT_MANUAL_LEVEL16: '978-988-70343-9-1',
  CC_STUDENT_MANUAL_LEVEL17: '978-988-70343-2-2',
  CC_STUDENT_MANUAL_LEVEL18: '978-988-70343-6-0',
  CC_STUDENT_MANUAL_LEVEL19: '978-988-70343-3-9',
  CC_STUDENT_MANUAL_LEVEL20: '978-988-70343-7-7'
};
