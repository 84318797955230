import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgressBar from '../../CircleProgressBar/CircleProgressBar';
import LevelCompletedIcon from '../../../assets/images/svg/level-completed-icon.svg';

import './LevelDetails.css';

// Importing helper
import getLastLessonInLevel from '../../../helpers/getLastLessonInLevel/getLastLessonInLevel';

/**
 * LevelDetails component
 * @param {object} levelData
 * @param {number} level
 * @returns {Component}
 */
const LevelDetails = ({ levelData, level }) => {
  const { studentid, levelid } = useParams();
  const history = useHistory();

  const isActivityInProgress = (levelData.reviewMode === 0
    && levelData.completionPercentage < 100)
    ? levelData.units.some((unit) => {
      return unit.lessons.some((lesson) => {
        return lesson.activities.some((activity) => {
          return activity.isLastActivityInLevel;
        });
      });
    })
    : false;

  /**
   * Function to redirect to player route with the last lesson in level details
   */
  const launchPlayer = () => {
    const lesson = getLastLessonInLevel(levelData);

    history.push({
      pathname: `/student/workbooksplayer/${lesson.lessonId}`,
      state: {
        studentId: studentid,
        lesson,
        levelid,
        reviewEnabled: false
      }
    });
  };

  return (
    <div className="mdc-layout-grid__cell--span-12 dsw-level-rectangle">
      <div className="mdc-layout-grid__inner">
        <div className="mdc-layout-grid__cell--span-1-phone
           mdc-layout-grid__cell--span-2-tablet
           mdc-layout-grid__cell--span-2-desktop"
        >
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-1">
              <div className="level-rectangle-strap" />
            </div>
            <div className="mdc-layout-grid__cell--span-3-phone
              mdc-layout-grid__cell--span-7-tablet
              mdc-layout-grid__cell--span-11-desktop
              mdc-layout-grid__cell--align-middle"
            >
              <h4 className="dsw-level-title">
                {`Level ${level}`}
              </h4>
            </div>
          </div>
        </div>
        <div className="mdc-layout-grid__cell--span-1-phone
           mdc-layout-grid__cell--span-2-tablet
           mdc-layout-grid__cell--span-2-desktop"
        >
          <div className="div-circular-bar">
            {levelData.completionPercentage === 100 ? (
              <img
                src={LevelCompletedIcon}
                alt="level completed icon"
              />
            ) : (
              levelData.completionPercentage > 0 && (
                <CircularProgressBar
                  strokeWidth="4"
                  sqSize="60"
                  percentage={levelData.completionPercentage}
                  showpercentage
                />
              ))}
          </div>
        </div>
        <div className="mdc-layout-grid__cell--span-2-phone
          mdc-layout-grid__cell--span-4-tablet
          mdc-layout-grid__cell--span-8-desktop
          mdc-layout-grid--align-right"
        >
          {isActivityInProgress
            && (
              <div
                className="dsw-btn-resume"
                onClick={launchPlayer}
              >
                Continue
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

LevelDetails.propTypes = {
  levelData: PropTypes.object.isRequired,
  level: PropTypes.string.isRequired
};

export default LevelDetails;
