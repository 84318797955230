import React from 'react';
import PropTypes from 'prop-types';

/**
 * To create custom carousel arrow button
 * @param {Function} next
 * @param {Function} previous
 * @param {object} rest
 */
const CarouselCustomButton = ({ next, previous, ...rest }) => {
  const { carouselState: { slidesToShow, currentSlide, totalItems } } = rest;
  const displayRightArrow = (currentSlide === (totalItems - slidesToShow)) ? 'd-none' : '';

  return (
    <div className="dsw-carousel-custom-group">
      <button
        aria-label="Go to previous slide"
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left ${currentSlide === 0 ? 'd-none' : ''}`}
        type="button"
        onClick={() => { return previous(); }}
      >
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            fill="#1A1A1A"
            d="M10.828 12L15.778 16.95 14.364 18.364 8 12 14.364 5.636 15.778 7.05z"
          />
        </svg>
      </button>
      <button
        aria-label="Go to next slide"
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right ${displayRightArrow}`}
        type="button"
        onClick={() => { return next(); }}
      >
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            fill="#1A1A1A"
            d="M13.172 12L8.222 7.05 9.636 5.636 16 12 9.636 18.364 8.222 16.95z"
          />
        </svg>
      </button>
    </div>
  );
};

CarouselCustomButton.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  rest: PropTypes.object
};

export default CarouselCustomButton;
