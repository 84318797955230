import { apiRequest } from '../../../helpers/apiRequest/apiRequest';
import { appLogout } from '../../../helpers/getAuthorizedState/getAuthorizedState';
import { rollbar } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get Print Pdf Url
 * @param {string} userId
 * @param {string} userFullName
 * @param {string} lessonId
 * @param {string} activityReferenceId
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @param {boolean} isStaffView
 */
const getPrintPdfUrl = (
  userId,
  userFullName,
  lessonId,
  activityReferenceId,
  reviewEnabled,
  notificationContext,
  loaderContext,
  isStaffView
) => {
  loaderContext.enableLoader(true);
  const url = isStaffView
    ? `${process.env.REACT_APP_GENERATE_PDF_URL}staff/${userId}/lesson/${lessonId}/pdf?activityId=${activityReferenceId}&userName=${userFullName}`
    : `${process.env.REACT_APP_GENERATE_PDF_URL}student/${userId}/lesson/${lessonId}/pdf?activityId=${activityReferenceId}&studentName=${userFullName}&isReviewMode=${reviewEnabled}`;
  return apiRequest('GET', url).then((response) => {
    loaderContext.enableLoader(false);
    if (response && response.status === 401) {
      appLogout();
    }
    return response.json();
  }).catch((response) => {
    loaderContext.enableLoader(false);
    notificationContext.setNotificationState({
      type: NOTIFICATION_TYPE.ATTENTION,
      title: SOMETHING_WENT_WRONG,
      message: ''
    });
    rollbar.error(response);
  });
};

export default getPrintPdfUrl;
