import { apiRequestWithDefaultHeader } from '../../../helpers/apiRequest/apiRequest';
import { rollbar } from '../../../components/RollbarConfig/RollbarConfig';
import { NOTIFICATION_TYPE, SOMETHING_WENT_WRONG } from '../../../constants/CONSTANTS';

/**
 * To get Privacy Policy Content
 * @param {string} policyUrl
 * @param {context} notificationContext
 * @param {context} loaderContext
 * @returns {Promise<object>}
 */
const getPrivacyPolicyContent = (policyUrl, notificationContext, loaderContext) => {
  loaderContext.enableLoader(true);
  return apiRequestWithDefaultHeader('GET', policyUrl)
    .then((response) => {
      loaderContext.enableLoader(false);
      return response;
    }).catch((response) => {
      loaderContext.enableLoader(false);
      notificationContext.setNotificationState({
        type: NOTIFICATION_TYPE.ATTENTION,
        title: SOMETHING_WENT_WRONG,
        message: ''
      });
      rollbar.error(response);
    });
};

export default getPrivacyPolicyContent;
