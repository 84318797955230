import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LevelDetails from './LevelDetails/LevelDetails';
import UnitList from './UnitList/UnitList';
import BookDescription from './BookDescription/BookDescription';
import { MY_LIBRARY } from '../../constants/CONSTANTS';
// Importing api service
import { getUserLevelProgress } from '../../api/services/TableOfContentService/TableOfContentService';
import mylibraryIcon from '../../assets/images/my-library-icon.png';
import BackArrowIcon from '../../assets/images/svg/back-arrow.svg';

import NotificationConfigContext from '../../context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from '../../context/CornerLoaderContext/CornerLoaderContext';

import './TableOfContent.css';

/**
 * TableOfContent component
 */
const TableOfContent = () => {
  const { studentid, levelid } = useParams();
  const [levelProgressData, setlevelProgressData] = useState(null);
  const history = useHistory();
  const notificationContext = useContext(NotificationConfigContext);
  const loaderContext = useContext(CornerLoaderContext);

  /**
   * To redirect my library
   */
  const backTo = () => {
    return history.push('/mylibrary');
  };

  useEffect(() => {
    getUserLevelProgress(studentid, levelid, notificationContext, loaderContext)
      .then((response) => {
        setlevelProgressData(response);
      });
  }, []);

  return (
    <>
      <div className="row">
        <div className="dsw-sub-header">
          <img
            className="dsw-back-arrow"
            src={BackArrowIcon}
            onClick={backTo}
            alt="back-arrow"
          />
          <img
            className="dsw-mylibrary-icon"
            src={mylibraryIcon}
            alt="mylibrary-icon"
          />
          <h4 className="dsw-mylibrary-title">{MY_LIBRARY}</h4>
        </div>
      </div>
      <div className="clear-both" />
      <div className="dsw-toc-container mdc-layout-grid">
        <div className="mdc-layout-grid__inner">
          <div className="mdc-layout-grid__cell--span-4-phone
          mdc-layout-grid__cell--span-8-tablet
          mdc-layout-grid__cell--span-3-desktop"
          >
            <img
              className="dsw-img-front_cover"
              src={require(`../../assets/images/wse-workbooks-front-level-${levelid}.png`)}
              alt="img-front_cover"
            />
          </div>
          <div className="mdc-layout-grid__cell--span-4-phone
          mdc-layout-grid__cell--span-8-tablet
          mdc-layout-grid__cell--span-9-desktop"
          >
            <div className="mdc-layout-grid__inner">
              {levelProgressData
                && (
                  <>
                    <LevelDetails levelData={levelProgressData} level={levelid} />
                    <UnitList levelData={levelProgressData} />
                    <BookDescription level={levelid} />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableOfContent;
