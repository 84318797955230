import React, {
  useEffect, useContext, useMemo, useState
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Importing workbooks player component
import WorkbooksPlayer from 'workbooks-player';

// Importing helpers
import mapActivityList from '../../helpers/mapActivityList/mapActivityList';

// Importing service
import { saveStudentAnswer, getStudentActivityProgress, getUserLevelProgress } from '../../api/services/TableOfContentService/TableOfContentService';
import { getEndOfLessonScore, saveEndOfLessonCompleted } from '../../api/services/endOfLessonService/endOfLessonService';
import { resetActivity, getReviewLesson } from '../../api/services/reviewService/reviewService';
import getPrintPdfUrl from '../../api/services/printPdfService/printPdfService';
import { getStudentInfo } from '../../api/services/idamApiService/idamApiService';

// Importing context
import NotificationConfigContext from '../../context/NotificationConfig/NotificationConfigContext';
import CornerLoaderContext from '../../context/CornerLoaderContext/CornerLoaderContext';

import { rollbar } from '../RollbarConfig/RollbarConfig';

import { SOMETHING_WENT_WRONG } from '../../constants/CONSTANTS';

/**
 * Player component
 * @returns {element}
 */
const Player = () => {
  const history = useHistory();
  const notificationContext = useContext(NotificationConfigContext);
  const loaderContext = useContext(CornerLoaderContext);
  const userState = JSON.parse(localStorage.getItem('UserState'));
  const location = useLocation();
  const param = useParams();
  let isEOLCompleted = false;
  const query = new URLSearchParams(location.search);
  const {
    lesson, studentId, reviewEnabled, levelid, redirectToDashboard
  } = location.state || {
    lesson: null,
    studentId: userState.userId,
    reviewEnabled: false,
    levelid: query.get('LevelNumber'),
    redirectToDashboard: query.get('RedirectToDashboard')
  };
  query.delete('RedirectToDashboard');
  const [lessonData, setLessonData] = useState(lesson);
  const [isRedirectEnabled, setRedirectStatus] = useState(false);

  /**
   * To get mapped lesson
   * @param {object} data
   * @returns {object}
   */
  const getMappedLesson = (data) => {
    const standardLesson = data.units[0].lessons[0];
    const activityData = mapActivityList(standardLesson.activityCount, standardLesson.activities);
    standardLesson.activities = activityData;
    return standardLesson;
  };

  /**
   * Function to navigate back to TOC page from player
   */
  const backToTableOfContent = () => {
    if (userState.isStaffToStudent) {
      localStorage.clear();
    }
    isEOLCompleted ? history.replace(`/${studentId}/toc/${levelid}`) : (redirectToDashboard ? (window.location.href = `${process.env.REACT_APP_NSE_DOMAIN_URL}${userState.isStaffToStudent ? `profile/${studentId}/studentView` : 'dashboard'}`) : history.goBack());
  };

  /**
   * To get student activity result
   * @param {string} activityReferenceId
   * @returns {object}
   */
  const getStudentActivityResult = (activityReferenceId) => {
    return getStudentActivityProgress(
      studentId, activityReferenceId, reviewEnabled, notificationContext
    );
  };

  /**
   * To save the student activity result and return promise to close player
   * @param {object} data
   * @returns {object}
   */
  const saveStudentActivityResult = (data) => {
    const activityData = {
      ...data,
      courseContentId: param.id
    };

    return saveStudentAnswer(studentId, activityData, reviewEnabled, notificationContext);
  };

  /**
   * To get student eol score and viewed status
   * @returns {object}
   */
  const getStudentEolScore = () => {
    return getEndOfLessonScore(studentId, param.id, notificationContext, loaderContext);
  };

  /**
   * Function to complete EOL and navigate student back to core course
   */
  const continueCourse = async () => {
    await saveEndOfLessonCompleted(studentId, param.id, notificationContext);
    setRedirectStatus(true);
    window.location.href = `${process.env.REACT_APP_NSE_DOMAIN_URL}dashboard`;
  };

  const goBackToDashboard = () => {
    window.location.href = `${process.env.REACT_APP_NSE_DOMAIN_URL}dashboard`;
  };

  /**
   * Function to complete EOL and navigate student back to TOC page
   */
  const backToWorkbooks = async () => {
    isEOLCompleted = true;
    backToTableOfContent();
    return saveEndOfLessonCompleted(studentId, param.id, notificationContext);
  };

  /**
   * To reset review lesson activity
   * @param {string} activityReferenceId
   */
  const resetReviewActivity = (activityReferenceId) => {
    resetActivity(studentId, activityReferenceId, notificationContext);
  };

  /**
   * To generate PDF in new tab
   * @param {string} activityReferenceId
   * @param {string} studentFullName
   */
  const printPdfHandler = (activityReferenceId, studentFullName) => {
    return getPrintPdfUrl(
      studentId,
      studentFullName,
      param.id,
      activityReferenceId,
      reviewEnabled,
      notificationContext,
      loaderContext,
      (userState.isStaffView && !userState.isStaffToStudent)
    ).then((data) => {
      if (data && data.status === 400) {
        rollbar.error(data);
        const tab = window.open('about:blank', '_blank');
        tab.document.write(`<div>${SOMETHING_WENT_WRONG}</div>`);
        tab.document.close();
        return;
      }
      window.open(data.pdf, '_blank');
    });
  };

  /**
   * To generate PDF based on role
   * @param {string} activityReferenceId
   */
  const printPdf = (activityReferenceId) => {
    if (userState.isStaffToStudent) {
      return getStudentInfo(studentId, notificationContext, loaderContext).then((studentInfo) => {
        printPdfHandler(activityReferenceId, studentInfo.fullName);
      });
    }
    return printPdfHandler(activityReferenceId, userState.studentFullName);
  };

  const configOptions = {
    contentUrl: lessonData && lessonData.url,
    domainName: process.env.REACT_APP_ASSET_URL,
    activities: lessonData && lessonData.activities,
    getSavedAnswer: getStudentActivityResult,
    getStudentEolScore,
    continueCourse,
    backToWorkbooks,
    saveStudentActivityResult,
    resetReviewActivity,
    printPdf,
    reviewEnabled,
    isStaffView: (userState.isStaffView && !userState.isStaffToStudent) || false,
    isViewOnly: (userState.isStaffToStudent && userState.isStaffView) || false,
    isRedirectEnabled,
    setRedirectStatus,
    goBackToDashboard
  };

  /**
   * Function to set the lesson data for review lesson on refresh
   */
  const setReviewLesson = () => {
    getReviewLesson(studentId, param.id, notificationContext, loaderContext)
      .then((response) => {
        const mappedLessonData = getMappedLesson(response);
        setLessonData(mappedLessonData);
      });
  };

  /**
   * Function to set the lesson data for standard lesson on refresh
   */
  const setStandardLesson = () => {
    getUserLevelProgress(studentId, levelid, notificationContext, loaderContext, param.id)
      .then((response) => {
        const mappedLessonData = getMappedLesson(response);
        setLessonData(mappedLessonData);
      });
  };

  // To updated the lesson on page refresh
  useEffect(() => {
    if (!lessonData) {
      (reviewEnabled) ? setReviewLesson() : setStandardLesson();
    }
  }, [lessonData]);

  // To reset the lesson data on page refresh
  useEffect(() => {
    if (history.location.state && history.location.state.lesson) {
      history.replace(`/student/workbooksplayer/${param.id}`, {
        ...history.location.state,
        lesson: null
      });
    }
  }, []);

  return useMemo(() => {
    return lessonData && (
      <WorkbooksPlayer
        configOptions={configOptions}
        close={backToTableOfContent}
      />
    );
  }, [lessonData]);
};

export default Player;
