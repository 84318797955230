import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgressBar from '../../../CircleProgressBar/CircleProgressBar';

import './BookTile.css';

/**
 * Tileview booktile component
 * @param {object} levelDetails
 * @param {string} studentId
 * @param {boolean} isCurrentLevel
 * @returns {Component}
 */
const BookTile = ({ levelDetails, studentId, isCurrentLevel }) => {
  const history = useHistory();
  const userState = JSON.parse(localStorage.getItem('UserState'));

  /**
   * To bind tile image
   * @returns {object}
   */
  const imageStyle = () => {
    const isDisabled = !levelDetails.inProgress ? '-disabled' : '';
    return {
      backgroundImage: `url(${require(`../../../../assets/images/wse-workbooks${isDisabled}-level-${levelDetails.id}.png`)})`,
      cursor: levelDetails.inProgress ? 'pointer' : 'auto'
    };
  };

  /**
   * which redirect to table of content
   * @returns
   */
  const redirectToTOC = () => {
    return levelDetails.inProgress ? history.push(`${studentId}/toc/${levelDetails.id}`) : false;
  };

  return (
    <>
      {levelDetails && (
        <div className="dsw-book-wrapper">
          <div
            className={`dsw-book-tile ${isCurrentLevel ? 'current-tile' : ''}`}
            style={imageStyle()}
            onClick={redirectToTOC}
          >
            {!userState.isStaffView && (
              <div className="dsw-book-progress">
                <label
                  className={(levelDetails.inProgress && levelDetails.progress < 100) ? 'd-block' : 'd-none'}
                >
                  {`${levelDetails.progress}%`}
                </label>
                {levelDetails.progress === 100
                  ? (
                    <i
                      className="fa fa-check-circle"
                      aria-hidden="true"
                    />
                  )
                  : (
                    <CircularProgressBar
                      strokeWidth="4"
                      sqSize="28"
                      percentage={levelDetails.progress}
                      showpercentage={false}
                      circleactive={levelDetails.inProgress}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

BookTile.propTypes = {
  levelDetails: PropTypes.object,
  studentId: PropTypes.string.isRequired,
  isCurrentLevel: PropTypes.bool.isRequired
};

export default BookTile;
