import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgressBar from '../CircleProgressBar/CircleProgressBar';
import './ListProgressTile.css';

/**
 * MyLibrary level progress list component
 * @param {Array<object>} levelProgressData
 * @param {string} studentId
 * @returns {Component}
 */
const ListProgressTile = ({ levelProgressData, studentId }) => {
  const history = useHistory();
  const userState = JSON.parse(localStorage.getItem('UserState'));
  const redirectTo = (item) => {
    return item.inProgress ? history.push(`${studentId}/toc/${item.id}`) : false;
  };

  return (levelProgressData
    && levelProgressData.map((item, index) => {
      return (
        <div key={index}>
          <div className="dsw-level-block" onClick={() => { return redirectTo(item); }}>
            <div className="d-flex">
              <h5
                className={`dsw-level-label ${!item.inProgress ? 'color-disable' : ''}`}
                data-testid={`${!item.inProgress ? 'disabled' : 'enabled'}-level-list`}
              >
                {`Level ${item.id}`}
              </h5>
              {!userState.isStaffView && (
                <>
                  <label
                    className={`dsw-level-percentage ${(item.inProgress && item.progress < 100) ? 'd-block' : 'd-none'}`}
                    x="50%"
                    y="50%"
                    dy=".3em"
                    data-testid={item.progress !== 100 && 'level-inprogress-list'}
                    textAnchor="middle"
                  >
                    {`${item.progress}%`}
                  </label>
                  {item.progress === 100
                    ? (
                      <i
                        className="fa fa-check-circle"
                        data-testid="level-completed-list"
                        aria-hidden="true"
                      />
                    )
                    : (
                      <CircularProgressBar
                        strokeWidth="4"
                        sqSize="28"
                        percentage={item.progress}
                        showpercentage={false}
                        circleactive={item.inProgress}
                      />
                    )}
                </>
              )}
            </div>
            <div className={`line-solid ${index === 9 ? 'disable' : ''}`} />
          </div>
          <div className="clear-both" />
        </div>
      );
    })
  );
};

ListProgressTile.propTypes = {
  levelProgressData: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentId: PropTypes.string.isRequired
};

export default ListProgressTile;
